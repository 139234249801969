import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { config, useSpring, animated } from "react-spring";
import Scroller from "../../../Utilities/Scroller.ts";
import NavItemStatus from "../../NavBar/NavItemStatus";

const ProductsServices = ({ colors, products }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("productsServicesLink");
    document.title = "Products & Services - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetProductAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: (250 * (num + 1)) / 2,
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1600px" }}>
      <div className="mx-3">
        <h1 className="siteHeader mb-4">Products &amp; Services</h1>
        <div>
          <Grid container spacing={5}>
            {products.map((product) => (
              <Grid
                key={`product${products.indexOf(product)}`}
                item
                sm={12}
                md={6}
                lg={4}
              >
                <animated.div
                  style={{
                    height: "100%",
                    ...GetProductAnimation(products.indexOf(product)),
                  }}
                  className="d-flex flex-column shadow rounded-3 text-center p-3"
                >
                  <div className="flex-grow-1 px-3">
                    <h2 className="desktopCaption">{product.Name}</h2>
                    <Link to={product.LinkTo}>
                      <img
                        width="270px"
                        src={product.ImageUrl}
                        className="rounded-3 mt-2 mb-4"
                        alt={product.Name}
                      />
                    </Link>

                    <p
                      className="desktopParagraph"
                      style={{ fontSize: "20px" }}
                    >
                      {product.Description}
                    </p>
                  </div>
                  <Link
                    style={{ backgroundColor: colors.Primary }}
                    className="mx-auto flex-shrink-1 btn rounded-pill p-3"
                    to={product.LinkTo}
                  >
                    Learn More
                  </Link>
                </animated.div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ProductsServices;
