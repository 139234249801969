import React, { useEffect } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../../Utilities/Scroller.ts";
import { useNavigate } from "react-router-dom";
import verticalBanner from "./20-05048-mcc-digitalassets_displayad_genericy_160x600-v1.jpeg";
import qrCode from "./qr-code.png";
import lastImg from "./image16.jpg";
import "./Financing.css";
import { config, useSpring, animated } from "react-spring";

const Financing = ({ dbSettings }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("financingLink");
    document.title = "Financing - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  const GetAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: (250 * (num + 1)) / 2,
    });
  };

  return (
    <div
      className="mx-3 mx-lg-auto mt-4 shadow rounded-3 pt-3"
      style={{ maxWidth: "1100px" }}
    >
      <div className="text-center row m-1 m-lg-4">
        <animated.div className="col-12 col-lg-10" style={GetAnimation(0)}>
          <img
            className="rounded-3 shadow"
            style={{ maxWidth: "100%", cursor: "pointer" }}
            src={dbSettings.MainImg.Src}
            alt={dbSettings.MainImg.Alt}
            onClick={() => navigate("/financing/synchrony")}
          />
          <p className="desktopParagraphLarge mt-4 mt-md-5 text-start">
            {dbSettings.MainContent}
          </p>
        </animated.div>
        <animated.div
          className="col-12 col-lg-2 desktopParagraphLarge financingDesktopDiv"
          style={GetAnimation(1)}
        >
          <a href="https://www.mysynchrony.com/mmc/MI68534300">
            <img
              className="rounded-3 shadow"
              alt="Flexible financing available through Synchrony"
              src={verticalBanner}
            />
          </a>
          <p className="desktopParagraph mt-4 mb-3">
            Use the QR Code to Apply on your cell phone or tablet
          </p>
          <img
            style={{ width: 160 }}
            className="d-block mx-auto"
            src={qrCode}
          />
        </animated.div>
        <animated.a
          style={GetAnimation(1)}
          className="financingMobileAnchor mb-3"
          href="https://www.mysynchrony.com/mmc/MI68534300"
        >
          <img
            style={{ maxWidth: "100%" }}
            className="rounded-3 shadow"
            alt="Apply now with Synchrony"
            src="https://www.synchronybusiness.com/mmc/assets/syf_apply_218.png"
          />
        </animated.a>
      </div>
      <div className="bg-white rounded-3 rounded-bottom text-center">
        <a href="https://www.mysynchrony.com/mmc/MI68534300">
          <img
            src={lastImg}
            alt="Financing Details"
            className="mt-4"
            style={{ maxWidth: "60%" }}
          />
        </a>
      </div>
    </div>
  );
};

export default Financing;
