export default class NavItemStatus {
  static linkIds = [
    "homeLink",
    "productsServicesLink",
    "careMaintenanceLink",
    "faqsLink",
    "aboutUsLink",
    "contactLink",
    "luxuryVinylPlankLink",
    "carpetLink",
    "stoneTileLink",
    "woodLink",
    "quartzCountersLink",
    "showerWallsLink",
    "happyCustomersLink",
    "givingBackLink",
    "financingLink",
  ];

  static SetActiveLink = (id) => {
    this.linkIds.forEach((linkId) => {
      const elements = document.getElementsByClassName(linkId);
      const arr = Array.from(elements);
      arr.map((x) => {
        x.classList.remove("active");
      });
    });

    const elements = document.getElementsByClassName(id);
    const arr = Array.from(elements);
    arr.map((x) => {
      x.classList.add("active");
    });
  };
}
