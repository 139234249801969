import { colors } from "@material-ui/core";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";

const NotFound = ({ colors }) => {
  const navigate = useNavigate();

  const handleBtnClick = (path) => {
    navigate(path);
  };

  return (
    <div className={`m-3 row`}>
      <div className="mx-auto text-center">
        <Icon
          iconName="exclamation-circle"
          color={colors.Secondary}
          size="3em"
        />
      </div>
      <span className="text-center">
        Oops, we can't find what you're looking for.
      </span>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        Home
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/products-services")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        Products &amp; Services
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/care-maintenance")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        Care &amp; Maintenance
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/faqs")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        FAQs
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/about-us")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        About Us
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/contact")}
        className={`mt-3 d-block btn btn-black border border-2 mx-auto`}
      >
        Contact
      </button>
    </div>
  );
};

export default NotFound;
