// import { Dropdown } from "bootstrap"; //IMPORTANT: KEEP THIS
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../Icon";
import "./NavBar.css";

const NavBar = ({
  dbSettings,
  colors,
  productsServicesDropdownIcon,
  productsServicesDropdownAction,
  aboutUsDropdownIcon,
  setAboutUsDropdownIcon,
}) => {
  const navigate = useNavigate();

  const productsServicesNestedDropdownIdMobile =
    "productsServicesDropdownMenuMobile";
  const aboutUsNestedDropdownIdMobile = "aboutUsDropdownMenuMobile";

  const nestedDropdownIdsMobile = [
    productsServicesNestedDropdownIdMobile,
    aboutUsNestedDropdownIdMobile,
  ];

  const productsServicesNestedDropdownId = "productsServicesDropdownMenu";
  const aboutUsNestedDropdownId = "aboutUsDropdownMenu";

  const nestedDropdownIds = [
    productsServicesNestedDropdownId,
    aboutUsNestedDropdownId,
  ];

  const SetDropdownIconsToClosed = () => {
    if (productsServicesDropdownIcon == "caret-up-fill") {
      productsServicesDropdownAction("caret-down-fill");
    }

    if (aboutUsDropdownIcon == "caret-up-fill") {
      setAboutUsDropdownIcon("caret-down-fill");
    }
  };

  const SetAllButThisDropdownIconToClosed = (action) => {
    SetDropdownIconsToClosed();
    action();
  };

  const GenerateLinks = (id, linkTo, isActive, label) => {
    return (
      <>
        {GenerateMobileNavLink(id, linkTo, isActive, label)}
        {GenerateDesktopNavLink(id, linkTo, isActive, label)}
      </>
    );
  };

  const GenerateDropdownLinks = (
    id,
    dropdownMenuId,
    linkTo,
    isActive,
    label,
    children,
    dropdownIconRef,
    dropDownAction
  ) => {
    return (
      <>
        {GenerateMobileNavLinkDropDown(
          id,
          dropdownMenuId + "Mobile",
          linkTo,
          isActive,
          label,
          children,
          dropdownIconRef,
          dropDownAction
        )}
        {GenerateDesktopNavLinkDropdown(
          id,
          dropdownMenuId,
          linkTo,
          isActive,
          label,
          children,
          dropdownIconRef,
          dropDownAction
        )}
      </>
    );
  };

  const GenerateMobileNavLink = (id, linkTo, isActive, label) => {
    return (
      <li className="nav-item mobileNavLink">
        <Link
          to={linkTo}
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          className={`${id} nav-link navBarLink text-nowrap ${
            isActive ? "active" : ""
          }`}
          onClick={(e) => {
            SetDropdownIconsToClosed();

            nestedDropdownIdsMobile.map((id) => {
              if (document.getElementById(id).classList.contains("d-block")) {
                document.getElementById(id).classList.remove("d-block");
              }
            });

            navigate(linkTo);
          }}
        >
          {label}
        </Link>
      </li>
    );
  };

  const GenerateDesktopNavLink = (id, linkTo, isActive, label, xMargin = 2) => {
    return (
      <li className={`nav-item desktopNavLink mx-${xMargin}`}>
        <Link
          to={linkTo}
          className={`${id} nav-link navBarLink text-nowrap ${
            isActive ? "active" : ""
          }`}
          onClick={() => {
            SetDropdownIconsToClosed();

            nestedDropdownIds.map((id) => {
              if (document.getElementById(id).classList.contains("d-block")) {
                document.getElementById(id).classList.remove("d-block");
              }
            });
          }}
        >
          {label}
        </Link>
      </li>
    );
  };

  const GenerateMobileNavLinkDropDown = (
    id,
    dropdownMenuId,
    linkTo,
    isActive,
    label,
    children,
    dropdownIconRef,
    dropdownAction
  ) => {
    return (
      <>
        <div className="d-flex">
          {GenerateMobileNavLink(id, linkTo, isActive, label)}
          <li className="nav-item dropdown mobileNavLink">
            <button
              role="button"
              className="btn nav-link navBarLink text-nowrap p-0 pt-1 my-0 ms-2"
              onClick={() => {
                dropdownAction();

                nestedDropdownIdsMobile
                  .filter((x) => x != dropdownMenuId)
                  .map((id) => {
                    if (
                      document.getElementById(id).classList.contains("d-block")
                    ) {
                      document.getElementById(id).classList.remove("d-block");
                    }
                  });

                if (
                  document
                    .getElementById(dropdownMenuId)
                    .classList.contains("d-block")
                ) {
                  document
                    .getElementById(dropdownMenuId)
                    .classList.remove("d-block");
                } else {
                  document
                    .getElementById(dropdownMenuId)
                    .classList.add("d-block");
                }
              }}
            >
              <div className="responsiveDropdownIcon pt-2">
                <Icon iconName={dropdownIconRef} color={colors.Primary} />
              </div>
            </button>
          </li>
        </div>
        <ul
          id={dropdownMenuId}
          className="dropdown-menu"
          style={{
            margin: "0",
            backgroundColor: colors.Background,
            borderColor: colors.Primary,
          }}
        >
          {children.map((childLink) => (
            <div
              className={`px-${
                children.indexOf(childLink) % 2 == 0 ? "3" : "0"
              }`}
              key={`childLinkMobile${children.indexOf(childLink)}`}
            >
              {childLink}
            </div>
          ))}
        </ul>
      </>
    );
  };

  const GenerateDesktopNavLinkDropdown = (
    id,
    dropdownMenuId,
    linkTo,
    isActive,
    label,
    children,
    dropdownIconRef,
    dropDownAction
  ) => {
    return (
      <div className="d-inline-flex">
        <div className="d-inline-flex">
          {GenerateDesktopNavLink(id, linkTo, isActive, label, 0)}
          <li className="nav-item dropdown desktopNavLink">
            <button
              role="button"
              className="btn nav-link navBarLink text-nowrap px-0 py-1 m-0"
              onClick={() => {
                dropDownAction();

                nestedDropdownIds
                  .filter((x) => x != dropdownMenuId)
                  .map((id) => {
                    if (
                      document.getElementById(id).classList.contains("d-block")
                    ) {
                      document.getElementById(id).classList.remove("d-block");
                    }
                  });

                if (
                  document
                    .getElementById(dropdownMenuId)
                    .classList.contains("d-block")
                ) {
                  document
                    .getElementById(dropdownMenuId)
                    .classList.remove("d-block");
                } else {
                  document
                    .getElementById(dropdownMenuId)
                    .classList.add("d-block");
                }
              }}
            >
              <Icon
                iconName={dropdownIconRef}
                color={colors.Primary}
                // size="2vh"
              />
            </button>
          </li>
        </div>
        <ul
          id={dropdownMenuId}
          className="dropdown-menu mt-5"
          style={{
            margin: "0",
            backgroundColor: colors.Background,
            borderColor: colors.Primary,
          }}
        >
          {children.map((childLink) => (
            <div key={`childLink${children.indexOf(childLink)}`}>
              {childLink}
            </div>
          ))}
        </ul>
      </div>
    );
  };

  const GenerateLogoBtn = () => {
    return (
      <>
        <Link
          to="/"
          className="col me-5 desktopNavLink desktopLogoBtn"
          onClick={() => {
            nestedDropdownIds.map((id) => {
              if (document.getElementById(id).classList.contains("d-block")) {
                document.getElementById(id).classList.remove("d-block");
              }
            });

            SetDropdownIconsToClosed();
          }}
        >
          <img
            src={dbSettings.DesktopLogoUrl}
            className="img-fluid mx-lg-3"
            alt="Flooring Creations Logo"
          />
        </Link>
        <Link
          to="/"
          className="col me-5 mobileNavLink"
          onClick={() => {
            SetDropdownIconsToClosed();
          }}
        >
          <img
            src={dbSettings.MobileLogoUrl}
            className="img-fluid"
            alt="Flooring Creations Logo"
          />
        </Link>
      </>
    );
  };

  return (
    <div className="sticky-top">
      <nav
        style={{ maxWidth: "1600px", backgroundColor: colors.Background }}
        className="mx-auto navbar navbar-expand-lg sticky-top my-2"
      >
        <div className="container-fluid ms-lg-0 ms-2 ps-0 justify-content-between">
          {GenerateLogoBtn()}
          <button
            id="togglerBtn"
            className="navbar-toggler btn p-0 m-0"
            style={{ borderStyle: "none" }}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="responsiveIcon">
              <Icon
                id="navbarIcon"
                iconName="three-dots-vertical"
                color={colors.Primary}
              />
            </div>
          </button>
          <div className="offcanvas offcanvas-end" id="navbarNavDropdown">
            <div className="offcanvas-header p-2">
              <Link
                to="/"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                className="col me-5 mobileNavLink"
                onClick={() => {
                  navigate("/");

                  SetDropdownIconsToClosed();

                  nestedDropdownIdsMobile.map((id) => {
                    if (
                      document.getElementById(id).classList.contains("d-block")
                    ) {
                      document.getElementById(id).classList.remove("d-block");
                    }
                  });
                }}
              >
                <img
                  src={dbSettings.DesktopLogoUrl}
                  className="img-fluid"
                  alt="Flooring Creations Logo"
                />
              </Link>
              <button
                type="button"
                className="btn p-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  SetDropdownIconsToClosed();

                  nestedDropdownIdsMobile.map((id) => {
                    if (
                      document.getElementById(id).classList.contains("d-block")
                    ) {
                      document.getElementById(id).classList.remove("d-block");
                    }
                  });
                }}
              >
                <div className="responsiveIcon">
                  <Icon
                    id="navbarIcon"
                    iconName="x-circle"
                    color={colors.Primary}
                  />
                </div>
              </button>
            </div>
            <hr
              className="m-0"
              style={{ borderColor: "black" }}
              id="offcanvasNavBarHr"
            />
            <div className="offcanvas-body col justify-content-end">
              <ul className="navbar-nav">
                {GenerateLinks("homeLink", "/", true, "Home")}
                <hr />
                {GenerateDropdownLinks(
                  "productsServicesLink",
                  "productsServicesDropdownMenu",
                  "/products-services",
                  false,
                  "Products & Services",
                  [
                    GenerateLinks(
                      "luxuryVinylPlankLink",
                      "/products-services/luxury-vinyl-plank",
                      false,
                      "Luxury Vinyl Plank"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "carpetLink",
                      "/products-services/carpet",
                      false,
                      "Carpet"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "stoneTileLink",
                      "/products-services/porcelain-tile",
                      false,
                      "Porcelain Tile"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "woodLink",
                      "/products-services/wood",
                      false,
                      "Wood"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "quartzCountersLink",
                      "/products-services/countertops",
                      false,
                      "Countertops"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "showerWallsLink",
                      "/products-services/shower-walls",
                      false,
                      "Shower & Tub Walls"
                    ),
                  ],
                  productsServicesDropdownIcon,
                  () => {
                    const newDropdownIcon =
                      productsServicesDropdownIcon == "caret-down-fill"
                        ? "caret-up-fill"
                        : "caret-down-fill";

                    SetAllButThisDropdownIconToClosed(() =>
                      productsServicesDropdownAction(newDropdownIcon)
                    );
                  }
                )}
                <hr />
                {GenerateLinks(
                  "financingLink",
                  "/financing",
                  false,
                  "Financing"
                )}
                <hr />
                {GenerateLinks(
                  "careMaintenanceLink",
                  "/care-maintenance",
                  false,
                  "Care & Maintenance"
                )}
                <hr />
                {GenerateLinks("faqsLink", "/faqs", false, "FAQs")}
                <hr />
                {GenerateDropdownLinks(
                  "aboutUsLink",
                  "aboutUsDropdownMenu",
                  "/about-us",
                  false,
                  "About Us",
                  [
                    GenerateLinks(
                      "happyCustomersLink",
                      "/about-us/happy-customers",
                      false,
                      "Happy Customers"
                    ),
                    <hr className="my-2" />,
                    GenerateLinks(
                      "givingBackLink",
                      "/about-us/giving-back",
                      false,
                      "Giving Back"
                    ),
                  ],
                  aboutUsDropdownIcon,
                  () => {
                    const newDropdownIcon =
                      aboutUsDropdownIcon == "caret-down-fill"
                        ? "caret-up-fill"
                        : "caret-down-fill";

                    SetAllButThisDropdownIconToClosed(() =>
                      setAboutUsDropdownIcon(newDropdownIcon)
                    );
                  }
                )}
                <hr />
                {GenerateLinks("contactLink", "/contact", false, "Contact")}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
