import React, { useEffect } from "react";
import Scroller from "../../../../Utilities/Scroller.ts";
import NavItemStatus from "../../../NavBar/NavItemStatus";
import "./Product.css";
import { config, useSpring, animated } from "react-spring";
import { useState } from "react";
import { Link } from "react-router-dom";

const Product = ({ product, colors }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink(product.NavBarClass);
    document.title = product.Name + " - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [product]);

  NavItemStatus.SetActiveLink(product.NavBarClass);
  document.title = product.Name + " - Flooring Creations";

  const GetProductAnimation = () => {
    return useSpring({
      from: { opacity: 0, x: -500 },
      to: { opacity: 1, x: 0 },
      config: config.gentle,
      delay: 100,
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1200px" }}>
      <animated.div
        style={GetProductAnimation()}
        className="mx-3 mx-lg-5 px-3 px-lg-5 py-3 shadow rounded-3"
      >
        <h1 className="siteHeader">{product.Name}</h1>
        {product.ProductSections.map((section) => (
          <div
            className="text-center"
            key={`productSection${product.ProductSections.indexOf(section)}`}
          >
            {section.DesktopImgUrl != null && section.MobileImgUrl != null ? (
              section.LinkTo != null ? (
                <a href={section.LinkTo} target="_blank">
                  <img
                    alt={section.Alt}
                    className="rounded-3 my-3 desktopProductImg mx-auto"
                    style={{
                      width: section.IsSmall ? "50%" : "100%",
                    }}
                    src={section.DesktopImgUrl}
                  />
                  <img
                    alt={section.Alt}
                    className="rounded-3 my-3 mobileProductImg"
                    style={{
                      width: "100%",
                    }}
                    src={section.MobileImgUrl}
                  />
                </a>
              ) : (
                <>
                  <img
                    alt={section.Alt}
                    className="rounded-3 my-3 desktopProductImg mx-auto"
                    style={{
                      width: section.IsSmall ? "50%" : "100%",
                    }}
                    src={section.DesktopImgUrl}
                  />
                  <img
                    alt={section.Alt}
                    className="rounded-3 my-3 mobileProductImg"
                    style={{
                      width: "100%",
                    }}
                    src={section.MobileImgUrl}
                  />
                </>
              )
            ) : section.MobileImgUrl != null ? (
              <img
                alt={section.Alt}
                className="rounded-3 my-3 mobileProductImg"
                style={{
                  width: "100%",
                }}
                src={section.MobileImgUrl}
              />
            ) : section.Paragraphs != null ? (
              section.Paragraphs.map((paragraph) => (
                <p
                  key={`sectionParagraph${section.Paragraphs.indexOf(
                    paragraph
                  )}`}
                  className="text-start desktopParagraph my-4"
                >
                  {paragraph}
                </p>
              ))
            ) : section.IframeSrc != null ? (
              <iframe
                title={section.VideoTitle}
                src={section.IframeSrc}
                width="710"
                style={{
                  maxWidth: "100%",
                }}
                height="399"
                allow="fullscreen"
                allowFullScreen=""
                className="my-3 rounded-3"
              />
            ) : section.VideoSrc != null ? (
              <video
                key={section.VideoSrc}
                title={section.VideoTitle}
                width="710"
                style={{
                  maxWidth: "100%",
                  borderStyle: "solid",
                  borderColor: colors.Secondary,
                  borderRadius: "15px",
                  backgroundColor: colors.Secondary,
                }}
                height="399"
                className="my-3"
                controls
              >
                <source src={section.VideoSrc} />
              </video>
            ) : section.BulletPoints != null ? (
              <ul className="text-start">
                {section.BulletPoints.map((bulletPoint) => (
                  <li
                    key={`sectionBulletPoint${section.BulletPoints.indexOf(
                      bulletPoint
                    )}`}
                    className="desktopParagraph my-4"
                  >
                    {bulletPoint}
                  </li>
                ))}
              </ul>
            ) : section.Link != null ? (
              <a
                href={section.Link}
                target="_blank"
                className="desktopParagraph"
              >
                <p
                  style={{ overflowWrap: "break-word" }}
                  className="text-start text-black desktopParagraphLink "
                >
                  {section.Link}
                </p>
              </a>
            ) : section.Header != null ? (
              <>
                <hr
                  style={{
                    borderTopWidth: "2px",
                    opacity: "1",
                    color: colors.Primary,
                  }}
                />
                <h2 className="text-lg-start desktopCaption">
                  {section.Header}
                </h2>
              </>
            ) : (
              <div></div>
            )}
          </div>
        ))}
      </animated.div>
    </div>
  );
};

export default Product;
