import React, { useEffect } from "react";
import NavItemStatus from "../../../NavBar/NavItemStatus";
import Scroller from "../../../../Utilities/Scroller.ts";
import Grid from "@material-ui/core/Grid";
import { config, useSpring, animated } from "react-spring";
import Icon from "../../../Icon";

const HappyCustomers = ({ dbSettings, colors }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("happyCustomersLink");
    document.title = "Happy Customers - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: (250 * (num + 1)) / 2,
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1400px" }}>
      <div className="mx-3">
        <animated.h1 className="siteHeader mb-4" style={GetAnimation(0)}>
          Our Happy Customers
        </animated.h1>
        <div className="mx-auto">
          <Grid container spacing={5}>
            {dbSettings.Reviews.map((review) => (
              <Grid
                key={`review${dbSettings.Reviews.indexOf(review)}`}
                item
                md={6}
              >
                <animated.div
                  className="rounded-3 shadow text-center desktopParagraphSmall p-4 p-lg-5 d-flex flex-column"
                  style={{
                    height: "100%",
                    ...GetAnimation(dbSettings.Reviews.indexOf(review) + 1),
                  }}
                >
                  <Icon
                    iconName={review.IconName}
                    color={
                      review.IconName == "yelp" ? colors.Yelp : colors.Google
                    }
                    size="10vh"
                  />
                  <p className="mt-3 desktopParagraph text-start flex-grow-1">
                    "{review.Content}"
                  </p>

                  <div className="text-end">
                    <Icon
                      iconName="star-fill"
                      color={
                        review.IconName == "yelp" ? colors.Yelp : colors.Google
                      }
                      size="3vh"
                    />
                    <Icon
                      iconName="star-fill"
                      color={
                        review.IconName == "yelp" ? colors.Yelp : colors.Google
                      }
                      size="3vh"
                    />
                    <Icon
                      iconName="star-fill"
                      color={
                        review.IconName == "yelp" ? colors.Yelp : colors.Google
                      }
                      size="3vh"
                    />
                    <Icon
                      iconName="star-fill"
                      color={
                        review.IconName == "yelp" ? colors.Yelp : colors.Google
                      }
                      size="3vh"
                    />
                    <Icon
                      iconName="star-fill"
                      color={
                        review.IconName == "yelp" ? colors.Yelp : colors.Google
                      }
                      size="3vh"
                    />
                    <p className="mb-0 desktopParagraph">{review.Name}</p>
                    <p className="mb-0 desktopParagraph">{review.City}</p>
                    <p className="mb-0 desktopParagraph">{review.Date}</p>
                  </div>
                </animated.div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default HappyCustomers;
