import React, { Component } from "react";

class Icon extends Component {
  render() {
    const className = "bi bi-" + this.props.iconName;
    return (
      <i
        className={className}
        style={{ fontSize: this.props.size, color: this.props.color }}
      />
    );
  }
}

export default Icon;
