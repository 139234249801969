import React, { useEffect } from "react";
import NavItemStatus from "../../../NavBar/NavItemStatus";
import Scroller from "../../../../Utilities/Scroller.ts";
import Grid from "@material-ui/core/Grid";
import { config, useSpring, animated } from "react-spring";

const GivingBack = ({ dbSettings }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("givingBackLink");
    document.title = "Giving Back - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: (250 * (num + 1)) / 2,
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1000px" }}>
      <div className="mx-3">
        <animated.h1
          className="siteHeader mb-4 text-center"
          style={GetAnimation(0)}
        >
          Flooring Creations Proudly Supports The Following Organizations:
        </animated.h1>
        <div className="mx-auto">
          <Grid container spacing={5}>
            {dbSettings.OrganizationImgs.map((imgUrl) => (
              <Grid
                key={`orgImg${dbSettings.OrganizationImgs.indexOf(imgUrl)}`}
                item
                sm={6}
                className="my-2"
              >
                <animated.img
                  style={{
                    height: "100%",
                    width: "100%",
                    ...GetAnimation(
                      dbSettings.OrganizationImgs.indexOf(imgUrl) + 1
                    ),
                  }}
                  src={imgUrl}
                  className="rounded-3 shadow mx-auto"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default GivingBack;
