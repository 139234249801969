import React, { useEffect } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../../Utilities/Scroller.ts";
import Grid from "@material-ui/core/Grid";
import { config, useSpring, animated } from "react-spring";
import Icon from "../../Icon";
import "./FAQs.css";

const FAQs = ({ dbSettings, colors }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("faqsLink");
    document.title = "FAQs - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: (250 * (num + 1)) / 2,
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1000px" }}>
      <div className="mx-3">
        <animated.h1 className="siteHeader mb-4" style={GetAnimation(0)}>
          Frequently Asked Questions
        </animated.h1>
        <div>
          <Grid container spacing={5}>
            {dbSettings.Questions.map((question) => (
              <Grid
                key={`question${dbSettings.Questions.indexOf(question)}`}
                item
                sm={12}
              >
                <animated.div
                  style={{
                    height: "100%",
                    ...GetAnimation(dbSettings.Questions.indexOf(question) + 1),
                  }}
                  className="shadow rounded-3 text-center py-3 px-3 px-lg-5"
                >
                  <div className="px-3">
                    <h2 className="desktopCaption">{"Q: " + question.Q}</h2>
                    {question.ImageUrl != null ? (
                      <>
                        <img
                          style={{ maxWidth: "70%" }}
                          src={question.ImageUrl}
                          className="rounded-3 mx-auto mt-2 mb-4 faqDesktopImg"
                          alt={question.Alt}
                        />
                        <img
                          style={{ maxWidth: "100%" }}
                          src={question.ImageUrl}
                          className="rounded-3 mt-2 mb-4 faqMobileImg"
                          alt={question.Alt}
                        />
                      </>
                    ) : (
                      <div className="my-3">
                        <Icon
                          iconName={question.IconName}
                          color={
                            dbSettings.Questions.indexOf(question) % 2 == 0
                              ? colors.Primary
                              : colors.Secondary
                          }
                        />
                      </div>
                    )}
                    <p
                      className="desktopParagraph text-start"
                      style={{ fontSize: "20px" }}
                    >
                      {"A: " + question.A}
                    </p>
                  </div>
                </animated.div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
