import React, { useEffect } from "react";
import NavItemStatus from "../../../NavBar/NavItemStatus";
import Scroller from "../../../../Utilities/Scroller.ts";

const Synchrony = () => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("financingLink");
    document.title = "Redirecting... - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  window.location.replace("https://www.mysynchrony.com/mmc/MI68534300");
  return <p className="m-5">Redirecting...</p>;
};

export default Synchrony;
