import React, { useEffect } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../../Utilities/Scroller.ts";
import { config, useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
import "./AboutUs.css";

const AboutUs = ({ colors, dbSettings }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("aboutUsLink");
    document.title = "About Us - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetProductAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, x: -500 },
      to: { opacity: 1, x: 0 },
      config: config.gentle,
      delay: 100 * (num + 1),
    });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "1200px" }}>
      <animated.div
        style={GetProductAnimation(0)}
        className="mx-3 mx-lg-5 px-3 px-lg-5 py-3 shadow rounded-3"
      >
        <h1 className="siteHeader">About Us</h1>
        <img
          className="rounded-3 shadow"
          width="100%"
          src={dbSettings.TeamImgUrl}
          alt={dbSettings.TeamImgAlt}
        />
        <p className="text-start my-4 desktopParagraph">
          Flooring Creations, Inc. was founded by Gustavo Morgutia Sr, Gus Sr.
          as everybody knows him started in flooring at the young age of 16 as a
          helper on a carpet crew. This coincidence led to several ventures
          within the flooring industry, each bringing him closer to his goal of
          owning his own retail store.
        </p>
        <p className="text-start my-4 desktopParagraph">
          37 years later Flooring Creations, Inc. has the experience and culture
          of it's founder. This experience covers vast product knowledge,
          installation, decor and most importantly a genuine passion for helping
          customers instead of trying to sell them.
        </p>
        <p className="text-start my-4 desktopParagraph mx-5">
          "I never realized that my job was so simple, once I learned to help
          people everything else just seemed to fall into place. Our company
          culture &amp; philosophy is: We are in the business of helping people
          create beautiful homes, and beautiful homes begin with beautiful
          floors."
        </p>
        <p className="text-start my-4 desktopParagraph">
          Gus Sr. and his staff at Flooring Creations use easy to understand
          decor principles to help you find luxury vinyl plank (wood look)
          engineered wood, porcelain tile, carpet and countertops that
          compliment your existing decor, or desired decor. Our beautiful
          expansive showroom full of quality selections gives you virtually
          endless options to create new rooms or upgrade existing ones. For the
          best experience possible (much better than viewing floors online) you
          owe it to your project to visit the famous Gus's and get the proper
          help you deserve.
        </p>
        <p className="text-start my-4 desktopParagraph">
          Come in to Flooring Creations today to check out our{" "}
          <Link
            style={{ color: colors.Text }}
            className="desktopParagraphLink"
            to="/products-services/wood"
          >
            Wood
          </Link>
          ,{" "}
          <Link
            style={{ color: colors.Text }}
            className="desktopParagraphLink"
            to="/products-services/porcelain-tile"
          >
            Porcelain Tile
          </Link>
          ,{" "}
          <Link
            style={{ color: colors.Text }}
            className="desktopParagraphLink"
            to="/products-services/luxury-vinyl-plank"
          >
            Luxury Vinyl Plank
          </Link>
          ,{" "}
          <Link
            style={{ color: colors.Text }}
            className="desktopParagraphLink"
            to="/products-services/carpet"
          >
            Carpet
          </Link>
          , and{" "}
          <Link
            style={{ color: colors.Text }}
            className="desktopParagraphLink"
            to="/products-services/countertops"
          >
            Countertops
          </Link>
          !
        </p>
      </animated.div>

      {dbSettings.Employees.map((employee) => (
        <animated.div
          key={`employee${dbSettings.Employees.indexOf(employee)}`}
          style={GetProductAnimation(
            dbSettings.Employees.indexOf(employee) + 1
          )}
          className="mt-5 mx-3 mx-lg-5 px-3 px-lg-5 pb-3 pt-3 pt-lg-5 shadow rounded-3 row row-cols-1 row-cols-lg-2"
        >
          <div className="rounded-3 col mt-2 p-3">
            <img
              className="rounded-3 shadow mb-4 desktopImgAboutUs"
              width="100%"
              src={employee.DesktopImgUrl}
              alt={employee.Name}
            />
            <img
              className="rounded-3 shadow mb-2 mobileImgAboutUs"
              width="100%"
              src={employee.MobileImgUrl}
              alt={employee.Name}
            />
          </div>

          <div className="col">
            <h1 className="siteHeader mt-0 mt-lg-3 text-center">
              {employee.Name}
            </h1>
            <h2 className="desktopCaption text-center">{employee.Title}</h2>
            <div>
              {employee.Paragraphs.map((paragraph) => (
                <div
                  key={`employeeParagraph${employee.Paragraphs.indexOf(
                    paragraph
                  )}`}
                >
                  {employee.Quote != null &&
                  employee.Paragraphs.indexOf(paragraph) ==
                    employee.Quote.Index ? (
                    <>
                      <p className="my-4 desktopParagraph mx-5">
                        "{employee.Quote.Text}"
                      </p>
                      <p className="my-4 desktopParagraph">{paragraph}</p>
                    </>
                  ) : employee.Quote != null &&
                    employee.Paragraphs.indexOf(paragraph) ==
                      employee.Quote.Index - 1 ? (
                    <>
                      <p className="my-4 desktopParagraph">{paragraph}</p>
                      <p className="my-4 desktopParagraph mx-5">
                        "{employee.Quote.Text}"
                      </p>
                    </>
                  ) : (
                    <p className="my-4 desktopParagraph">{paragraph}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </animated.div>
      ))}
    </div>
  );
};

export default AboutUs;
