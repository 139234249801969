import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { config, useSpring, animated } from "react-spring";
import Scroller from "../../../Utilities/Scroller.ts";
import Icon from "../../Icon";
import NavItemStatus from "../../NavBar/NavItemStatus";
import "./Home.css";

const Home = ({ dbSettings, products, colors }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("homeLink");
    document.title = "Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetCaptionAnimation = () => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: 250,
    });
  };

  const GetRootAnimation = () => {
    return useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: config.gentle,
      delay: 300,
    });
  };

  const woodProduct = products.filter((x) => x.Name == "Wood")[0];
  const carpetProduct = products.filter((x) => x.Name == "Carpet")[0];
  const lvpProduct = products.filter((x) => x.Name == "Luxury Vinyl Plank")[0];
  const stoneTileProduct = products.filter(
    (x) => x.Name == "Porcelain Tile"
  )[0];
  const countersProduct = products.filter((x) => x.Name == "Countertops")[0];
  const showerWallsProduct = products.filter(
    (x) => x.Name == "Shower & Tub Walls"
  )[0];

  const navigate = useNavigate();

  return (
    <animated.div
      className="mx-auto"
      style={{ maxWidth: "1600px", ...GetRootAnimation() }}
    >
      <Carousel controls={false}>
        {dbSettings.ImageCarousel.map((image) => (
          <Carousel.Item
            interval={4000}
            key={`imgCarouselBtn${dbSettings.ImageCarousel.indexOf(image)}`}
            style={{ cursor: image.Clickable ? "pointer" : "" }}
            onClick={() => {
              if (image.Clickable) {
                navigate(image.LinkTo);
              }
            }}
          >
            <img
              className="w-100 desktopImg"
              src={image.DesktopUrl}
              alt={image.Alt}
              height="590px"
            />
            <img
              className="w-100 mobileImg"
              src={image.MobileUrl}
              alt={image.Alt}
            />
            <animated.div
              style={{
                backgroundColor: `${
                  image.HasBackground ? colors.Background : "transparent"
                }`,
                color: `${image.TextColor}`,
                textShadow: `${image.HasBackground ? "" : "1px 1px #000000"}`,
                position: "absolute",
                bottom: "2vw",
                left: "10vw",
                maxWidth: "85vw",
                ...GetCaptionAnimation(),
              }}
              className={`text-start ps-3 pe-5 rounded-3 ${
                image.HasBackground ? "pt-3" : ""
              }`}
            >
              <h1 className="m-0 desktopImgCarouselHeader">{image.Header}</h1>
              <h2 className="m-0 desktopImgCarouselCaption">{image.Caption}</h2>
              <h1 className="m-0 mobileImgCarouselHeader">{image.Header}</h1>
              <h2 className="m-0 mobileImgCarouselCaption">{image.Caption}</h2>
              {!image.Clickable ? (
                <Link
                  style={{
                    backgroundColor: colors.Primary,
                    textShadow: "0px 0px #000000",
                  }}
                  to={image.LinkTo}
                  className="btn p-3 my-5 rounded-pill"
                >
                  Read More
                </Link>
              ) : null}
            </animated.div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="my-5 my-md-3 border-bottom">
        <div className="mx-3 mx-md-5 px-md-5">
          <h1 className="siteHeader text-center mt-md-5 mb-md-3">
            "Beautiful Homes Begin with Beautiful Floors"
          </h1>
          <div className="row pb-5">
            <div id="quoteIconDiv" className="col-lg-3 col-md-12 text-center">
              <Icon
                iconName={dbSettings.QuoteIconName}
                color={colors.Secondary}
              />
            </div>
            <div id="quoteTextDiv" className="col-lg-9 col-md-12">
              <h2 style={{ color: colors.Text }} className="desktopCaption">
                Our philosophy is, we are in the business of helping people
                create beautiful homes, and beautiful homes begin with beautiful
                floors!
              </h2>
              <p className="desktopParagraph mt-3">
                Flooring Creations is a specialty flooring retailer in San
                Diego, specializing in residential flooring such as{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={woodProduct.LinkTo}
                >
                  {woodProduct.Name}
                </Link>{" "}
                Floors,{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={lvpProduct.LinkTo}
                >
                  {lvpProduct.Name}
                </Link>{" "}
                (wood &amp; tile look),{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={stoneTileProduct.LinkTo}
                >
                  {stoneTileProduct.Name}
                </Link>
                , and{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={carpetProduct.LinkTo}
                >
                  {carpetProduct.Name}
                </Link>
                . We also sell{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={countersProduct.LinkTo}
                >
                  {countersProduct.Name}
                </Link>
                , and Tile for{" "}
                <Link
                  style={{ color: colors.Text }}
                  className="desktopParagraphLink"
                  to={showerWallsProduct.LinkTo}
                >
                  {showerWallsProduct.Name} &amp; Tub Enclosure designs
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-3 mx-md-5 px-md-5">
        <h1 className="siteHeader text-center my-5">Featured Products</h1>
        <div
          className={`row row-cols-1 row-cols-md-2 row-cols-xxl-${dbSettings.FeaturedProducts.length} text-center`}
        >
          {dbSettings.FeaturedProducts.map((product) => (
            <Link
              key={`featuredProduct${dbSettings.FeaturedProducts.indexOf(
                product
              )}`}
              to={product.LinkTo}
              style={{ color: colors.Secondary }}
              className="col desktopCaption captionLink my-3 my-md-0"
            >
              <img
                alt={product.Alt}
                style={{
                  color: colors.Secondary,
                  width: "75vw",
                  maxWidth: "250px",
                }}
                className="rounded-3 desktopCaption"
                src={product.Url}
              />
              <p
                style={{ color: colors.Secondary }}
                className="desktopCaption captionLink mt-3"
              >
                {product.Label}
              </p>
            </Link>
          ))}
          <div className="mx-auto">
            <Link
              to="/products-services"
              style={{ backgroundColor: colors.Primary }}
              className="btn rounded-pill p-3"
            >
              More Products
            </Link>
          </div>
        </div>
      </div>

      <div
        className="text-center siteHeader mt-3 pt-5"
        style={{
          backgroundColor: colors.Secondary,
          position: "absolute",
          left: 0,
          right: 0,
        }}
      >
        <h1 className="mx-3 px-3 mx-md-5 px-md-5">
          Set up an appointment with one of the famous Gus's
        </h1>
        <Link
          to="/contact"
          style={{ backgroundColor: colors.Primary }}
          className="btn rounded-pill my-4 p-3"
        >
          Get Started
        </Link>
      </div>

      <div
        id="divUnderAbsoluteDiv"
        className="text-center"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/financing/synchrony")}
      >
        <img
          className="rounded-3 shadow homeDesktopImg"
          alt="Financing application image"
          src="https://flooringcreations-images.s3.us-west-1.amazonaws.com/asset_1320x800-01+2.jpg"
          style={{ maxWidth: "90vw" }}
        />
        <img
          className="rounded-3 shadow homeMobileImg"
          alt="Financing application image"
          src="https://flooringcreations-images.s3.us-west-1.amazonaws.com/asset_1320x800-01+3.jpg"
          style={{ maxWidth: "90vw" }}
        />
      </div>

      <div className="text-center mt-4 pt-3 px-5">
        <h1 className="siteHeader mb-5">Our Happy Customers</h1>
        <div
          className={`row row-cols-1 row-cols-xxl-${dbSettings.FeaturedReviews.length} text-center mb-3`}
        >
          {dbSettings.FeaturedReviews.map((review) => (
            <div
              className="my-md-3"
              key={`featuredReviewContent${dbSettings.FeaturedReviews.indexOf(
                review
              )}`}
            >
              <Icon iconName={review.IconName} color={colors.Yelp} />
              <p className="mt-3 desktopParagraph">"{review.Content}"</p>
              <p className="mb-5 mb-lg-0 desktopParagraph">- {review.Name}</p>
            </div>
          ))}
        </div>
        <Link
          to="/about-us/happy-customers"
          style={{ backgroundColor: colors.Yelp }}
          className="btn rounded-pill p-3 text-light"
        >
          More 5-Star Reviews
        </Link>
      </div>

      <div
        id="desktopMeetTheTeam"
        className="mt-5 px-3 row align-items-center mx-auto"
        style={{
          backgroundImage: `url(${dbSettings.DesktopMeetTheTeamImgUrl})`,
        }}
      >
        <div
          className="mx-5"
          style={{ backgroundColor: "transparent", maxWidth: "16vw" }}
        >
          <h1 className="text-center">Meet The Team</h1>
          <Link
            to="/about-us"
            style={{ backgroundColor: colors.Secondary }}
            className="d-block btn rounded-pill p-3 my-3"
          >
            About Us
          </Link>
        </div>
      </div>
      <div id="mobileMeetTheTeamHr" className="mt-4" />
      <div
        id="mobileMeetTheTeam"
        className="mt-4 mx-3 pt-3 pb-4 text-center shadow rounded-3"
      >
        <h1 className="siteHeader mb-3 text-center">Meet The Team</h1>
        <img
          className="rounded-3"
          id="mobileMeetTheTeamImg"
          style={{ width: "75%", maxWidth: "750px" }}
          src={dbSettings.MobileMeetTheTeamImgUrl}
          alt="Flooring Creations Employees"
        />
        <div
          className="mt-4 mx-auto"
          style={{ width: "75%", maxWidth: "750px" }}
        >
          <Link
            to="/about-us"
            style={{ backgroundColor: colors.Secondary }}
            className="d-block btn rounded-pill p-3 my-3"
          >
            About Us
          </Link>
          {/* <Link
            to="/contact"
            style={{ backgroundColor: colors.Secondary }}
            className="d-block btn rounded-pill p-3 mb-3"
          >
            Get In Touch
          </Link>
          <Link
            to="/about-us/giving-back"
            style={{ backgroundColor: colors.Secondary }}
            className="d-block btn rounded-pill p-3 mb-3"
          >
            Giving Back
          </Link>
          <Link
            to="/faqs"
            style={{ backgroundColor: colors.Secondary }}
            className="d-block btn rounded-pill p-3"
          >
            Where To Park
          </Link> */}
        </div>
      </div>

      <div className="mx-auto mt-3 px-3 text-center row row-cols-1 row-cols-lg-3">
        <Link
          to="/contact"
          className="col px-3 my-2"
          style={{ textDecoration: "none" }}
        >
          <div
            className="rounded-3 py-3 row align-items-center"
            style={{
              borderStyle: "solid",
              borderColor: colors.Primary,
              height: "100%",
            }}
          >
            <div className="rounded-3" style={{ borderColor: colors.Primary }}>
              <Icon iconName="chat-square-text" color={colors.Text} />
            </div>
            <div
              className="rounded-3 text-black desktopParagraphLarge"
              style={{ borderColor: colors.Primary }}
            >
              Get In Touch
            </div>
          </div>
        </Link>
        <Link
          to="/about-us/giving-back"
          className="col px-3 my-2"
          style={{ textDecoration: "none" }}
        >
          <div
            className="rounded-3 py-3 row align-items-center"
            style={{
              borderStyle: "solid",
              borderColor: colors.Primary,
              height: "100%",
            }}
          >
            <div className="rounded-3" style={{ borderColor: colors.Primary }}>
              <Icon iconName="bag-heart" color={colors.Text} />
            </div>
            <div
              className="rounded-3 text-black desktopParagraphLarge"
              style={{ borderColor: colors.Primary }}
            >
              Giving Back
            </div>
          </div>
        </Link>
        <Link
          to="/faqs"
          className="col px-3 my-2"
          style={{ textDecoration: "none" }}
        >
          <div
            className="rounded-3 py-3 row align-items-center"
            style={{
              borderStyle: "solid",
              borderColor: colors.Primary,
              height: "100%",
            }}
          >
            <div className="rounded-3" style={{ borderColor: colors.Primary }}>
              <Icon iconName="question-circle" color={colors.Text} />
            </div>
            <div
              className="rounded-3 text-black desktopParagraphLarge"
              style={{ borderColor: colors.Primary }}
            >
              Frequently Asked Questions
            </div>
          </div>
        </Link>
      </div>
    </animated.div>
  );
};

export default Home;
