import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default class Scroller {
  static ScrollToTop = (animate: Boolean) => {
    document.documentElement.style.scrollBehavior = animate ? "smooth" : "auto";
    window.scrollTo(0, 0);

    return null;
  };

  static ScrollToY = (yPosition) => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, yPosition);
    }, [pathname]);

    return null;
  };
}
