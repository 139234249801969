import React, { useState } from "react";
import { config, useSpring, animated } from "react-spring";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailKey from "../../../../EmailKey.js";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

const ContactForm = ({ colors, products }) => {
  const [YourName, SetYourName] = useState("");
  const [PhoneNumber, SetPhoneNumber] = useState("");
  const [EmailAddress, SetEmailAddress] = useState("");
  const [ZipCode, SetZipCode] = useState("");
  const [ApptType, SetApptType] = useState("");
  const [ProductType, SetProductType] = useState("");
  const [HomeArea, SetHomeArea] = useState("");
  const [Message, SetMessage] = useState("");

  const SubmitClicked = (e) => {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      if (!form.checkValidity()) {
        e.stopPropagation();
        e.preventDefault();
        form.classList.add("was-validated");
      } else {
        e.preventDefault();

        document.getElementById("spinner").classList.remove("d-none");

        var templateParams = {
          from_name: YourName,
          phone_number: PhoneNumber,
          from_email: EmailAddress,
          zip_code: ZipCode,
          appt_type: ApptType,
          product_type: ProductType,
          home_area: HomeArea,
          message: Message,
        };

        try {
          emailjs
            .send(
              "default_service",
              EmailKey.TEMPLATE_ID,
              templateParams,
              EmailKey.USER_ID
            )
            .catch(() => {
              toast.error("Something went wrong, try again.");
              document.getElementById("spinner").classList.add("d-none");
            })
            .then((x) => {
              if (x.status == 200) {
                toast.success("Message Sent!");

                SetYourName("");
                SetPhoneNumber("");
                SetEmailAddress("");
                SetZipCode("");
                SetApptType("");
                SetProductType("");
                SetHomeArea("");
                SetMessage("");

                form.classList.remove("was-validated");
              } else {
                toast.error("Something went wrong, try again.");
              }

              document.getElementById("spinner").classList.add("d-none");
            });
        } catch (error) {
          toast.error("Something went wrong, try again.");
          document.getElementById("spinner").classList.add("d-none");
        }
      }
    });
  };

  const GetAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: -50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: num * 100 + 350,
    });
  };

  const children = [
    <>
      <label
        htmlFor="nameFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Your Name*
      </label>
      <input
        required
        name="YourName"
        value={YourName}
        type="text"
        className="form-control"
        style={{ backgroundColor: "floralwhite" }}
        id="nameFormInput"
        placeholder="First &amp; Last Name"
        onChange={(e) => {
          const { value } = e.target;
          SetYourName(value);
        }}
      />
      <div className="invalid-feedback" style={{ fontSize: "18px" }}>
        Required
      </div>
    </>,
    <>
      <label
        htmlFor="phoneNumFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Phone Number*
      </label>
      <input
        required
        name="PhoneNumber"
        value={PhoneNumber}
        type="tel"
        className="form-control"
        style={{ backgroundColor: "floralwhite" }}
        id="phoneNumFormInput"
        placeholder="Phone #"
        onChange={(e) => {
          const { value } = e.target;
          SetPhoneNumber(value);
        }}
      />
      <div className="invalid-feedback" style={{ fontSize: "18px" }}>
        Required
      </div>
    </>,
    <>
      <label
        htmlFor="emailFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Email Address*
      </label>
      <input
        required
        name="EmailAddress"
        value={EmailAddress}
        type="email"
        className="form-control"
        style={{ backgroundColor: "floralwhite" }}
        id="emailFormInput"
        placeholder="name@example.com"
        onChange={(e) => {
          const { value } = e.target;
          SetEmailAddress(value);
        }}
      />
      <div className="invalid-feedback" style={{ fontSize: "18px" }}>
        Required
      </div>
    </>,
    <>
      <label
        htmlFor="zipCodeFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Zip Code*
      </label>
      <input
        required
        name="ZipCode"
        value={ZipCode}
        type="number"
        className="form-control"
        style={{ backgroundColor: "floralwhite" }}
        id="zipCodeFormInput"
        placeholder="91913"
        onChange={(e) => {
          const { value } = e.target;
          SetZipCode(value);
        }}
      />
      <div className="invalid-feedback" style={{ fontSize: "18px" }}>
        Required
      </div>
    </>,
    <>
      <label
        htmlFor="apptTypeFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Preferred Appointment Type
      </label>
      <select
        name="ApptType"
        value={ApptType}
        id="apptTypeFormInput"
        style={{ backgroundColor: "floralwhite" }}
        onChange={(e) => {
          const { value } = e.target;
          SetApptType(value);
        }}
        className="form-select"
        aria-label="Preferred Appointment Type Selection"
      >
        <option value="none">Select One</option>
        <option value="In-Store">In-Store</option>
        <option value="At Home">At Home</option>
      </select>
    </>,
    <>
      <label
        htmlFor="productTypeFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Product Interested In
      </label>
      <select
        name="ProuductType"
        value={ProductType}
        id="productTypeFormInput"
        style={{ backgroundColor: "floralwhite" }}
        onChange={(e) => {
          const { value } = e.target;
          SetProductType(value);
        }}
        className="form-select"
        aria-label="Product Type Selection"
      >
        <option value="none">Select One</option>
        {products.map((product) => (
          <option
            key={`product${products.indexOf(product)}`}
            value={product.Namae}
          >
            {product.Name}
          </option>
        ))}
      </select>
    </>,
    <>
      <label
        htmlFor="homeAreaFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Area Of Home
      </label>
      <select
        name="HomeArea"
        value={HomeArea}
        id="homeAreaFormInput"
        style={{ backgroundColor: "floralwhite" }}
        onChange={(e) => {
          const { value } = e.target;
          SetHomeArea(value);
        }}
        className="form-select"
        aria-label="Home Area Selection"
      >
        <option value="none">Select One</option>
        <option value="Living Room">Living Room</option>
        <option value="Kitchen">Kitchen</option>
        <option value="Bedroom(s)">Bedroom(s)</option>
        <option value="Bathroom(s)">Bathroom(s)</option>
        <option value="Laundry Room">Laundry Room</option>
        <option value="Multiple Areas">Multiple Areas</option>
        <option value="Entire Home">Entire Home</option>
      </select>
    </>,
    <>
      <label
        htmlFor="messageFormInput"
        className="desktopParagraph d-block mt-3 mb-1"
      >
        Message*
      </label>
      <textarea
        required
        name="Message"
        value={Message}
        className="form-control"
        style={{ backgroundColor: "floralwhite" }}
        id="messageFormInput"
        rows="5"
        placeholder="What would you like to know?"
        onChange={(e) => {
          const { value } = e.target;
          SetMessage(value);
        }}
      />
      <div className="invalid-feedback" style={{ fontSize: "18px" }}>
        Required
      </div>
    </>,
    <>
      <button
        type="submit"
        id="submitBtn"
        onClick={SubmitClicked}
        className="btn"
        style={{ backgroundColor: colors.Primary }}
      >
        Send
        <span
          id="spinner"
          className="spinner-border text-black spinner-border-sm ms-1 d-none"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </>,
  ];

  return (
    <>
      <ToastContainer
        toastClassName="toastClass"
        bodyClassName="toastBody"
        style={{ fontSize: "18px" }}
      />
      <form id="myContactForm" className="needs-validation" noValidate>
        {children.map((child) => (
          <animated.div
            key={`child${children.indexOf(child)}`}
            style={GetAnimation(children.indexOf(child))}
          >
            {child}
          </animated.div>
        ))}
      </form>
    </>
  );
};

export default ContactForm;
