import * as AWS from "aws-sdk";

export const GetDefaultData = () => {
  const data = {
    Id: {
      N: "0",
    },
    Footer: {
      M: {
        DesktopLogoUrl: {
          S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/logo.png",
        },
        HoursSections: {
          L: [
            {
              M: {
                DaysLabel: {
                  S: "Monday - Friday:",
                },
                HoursLabel: {
                  S: "10:00 a.m. - 6:00 p.m.",
                },
              },
            },
            {
              M: {
                DaysLabel: {
                  S: "Saturday:",
                },
                HoursLabel: {
                  S: "10:00 a.m. - 5:00 p.m.",
                },
              },
            },
            {
              M: {
                DaysLabel: {
                  S: "Sunday:",
                },
                HoursLabel: {
                  S: "Closed",
                },
              },
            },
          ],
        },
        MobileLogoUrl: {
          S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/logo.png",
        },
        Socials: {
          L: [
            {
              M: {
                IconName: {
                  S: "facebook",
                },
                Url: {
                  S: "https://www.facebook.com/Flooring-Creations-324175547611381/",
                },
              },
            },
            {
              M: {
                IconName: {
                  S: "pinterest",
                },
                Url: {
                  S: "https://www.pinterest.com/floorcreations/",
                },
              },
            },
            {
              M: {
                IconName: {
                  S: "youtube",
                },
                Url: {
                  S: "https://www.youtube.com/user/floor4beauty",
                },
              },
            },
            {
              M: {
                IconName: {
                  S: "twitter",
                },
                Url: {
                  S: "https://twitter.com/FloorCreations",
                },
              },
            },
          ],
        },
      },
    },
    NavBar: {
      M: {
        DesktopLogoUrl: {
          S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/logo.png",
        },
        MobileLogoUrl: {
          S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/logo.png",
        },
      },
    },
    Pages: {
      L: [
        {
          M: {
            DesktopMeetTheTeamImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-29+at+8.25.45+PM.png",
            },
            FeaturedProducts: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Luxury Vinyl Plank Flooring",
                    },
                    Label: {
                      S: "Luxury Vinyl Plank",
                    },
                    LinkTo: {
                      S: "/products-services/luxury-vinyl-plank",
                    },
                    Url: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/gRPNP25s+2.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Flooring",
                    },
                    Label: {
                      S: "Carpet",
                    },
                    LinkTo: {
                      S: "/products-services/carpet",
                    },
                    Url: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Exquisite+Portrait.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Porcelain Tile Flooring",
                    },
                    Label: {
                      S: "Porcelain Tile",
                    },
                    LinkTo: {
                      S: "/products-services/porcelain-tile",
                    },
                    Url: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/featured-product-porcelain-plank.jpg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Wood Flooring",
                    },
                    Label: {
                      S: "Wood",
                    },
                    LinkTo: {
                      S: "/products-services/wood",
                    },
                    Url: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/TP3sdWJA+2.jpeg",
                    },
                  },
                },
              ],
            },
            FeaturedReviews: {
              L: [
                {
                  M: {
                    Content: {
                      S: "THANK YOU Flooring Creations for doing such an amazing job at replacing our stairs and second floor with new carpet and vinyl floors. We LOVE how beautiful they look! Pricing was affordable, customer service was amazing and they were able to finish up everything in just one week! Highly recommend if you're looking for quick, reliable and trustworthy people to install new floors! :)",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Stephanie T.",
                    },
                  },
                },
                {
                  M: {
                    Content: {
                      S: "Flooring Creations was fabulous to work with.  They suggested a carpet quality that was so soft and luxurious!  They also helped us to replace all the baseboards after our flood.  Timely and affordable too.",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Wendy I.",
                    },
                  },
                },
                {
                  M: {
                    Content: {
                      S: "This is our second time using the flooring creation shop and both experiences have been wonderful! They have a great selection of quality materials and are very helpful with both the selection process and installation. Plus they have a wonderful playroom for kids which really helps while you're taking your time to make these important decisions.",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Megan C.",
                    },
                  },
                },
              ],
            },
            ImageCarousel: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Wood Flooring",
                    },
                    Caption: {
                      S: "Wood flooring is the most desirable floor due to its natural beauty & classical endurance",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-23+at+9.56.08+PM.png",
                    },
                    HasBackground: {
                      BOOL: false,
                    },
                    Header: {
                      S: "WOOD FLOORS",
                    },
                    LinkTo: {
                      S: "/products-services/wood",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-07+at+5.23.34+PM.jpeg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Tile Flooring",
                    },
                    Caption: {
                      S: "The toughest material available with an endless variety of colors & styles",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-tile.jpg",
                    },
                    HasBackground: {
                      BOOL: false,
                    },
                    Header: {
                      S: "PORCELAIN TILE",
                    },
                    LinkTo: {
                      S: "/products-services/porcelain-tile",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-tile-550x550.jpg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Puppy Pee on Luxury Vinyl Plank",
                    },
                    Caption: {
                      S: "Get the look of real wood with the toughness, softness, and water-proof features of vinyl",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/dog-pee-slide+USE.jpg",
                    },
                    Header: {
                      S: "LUXURY VINYL PLANK",
                    },
                    LinkTo: {
                      S: "/products-services/luxury-vinyl-plank",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/dog-pee-slide-550x550.jpg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet flooring",
                    },
                    Caption: {
                      S: "Carpet adds warmth and comfort to your home",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-07+at+5.35.03+PM+2.jpeg",
                    },
                    Header: {
                      S: "CARPET FLOORS",
                    },
                    LinkTo: {
                      S: "/products-services/carpet",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-07+at+5.35.03+PM.jpeg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Countertop",
                    },
                    Caption: {
                      S: "The finishing touch for your beautiful kitchen",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-quartz-countertops-1.jpg",
                    },
                    HasBackground: {
                      BOOL: false,
                    },
                    Header: {
                      S: "COUNTERTOPS",
                    },
                    LinkTo: {
                      S: "/products-services/countertops",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-quartz-countertops-1-550x550.jpg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Financing application image",
                    },
                    Caption: {
                      S: "",
                    },
                    Clickable: {
                      BOOL: true,
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/asset_1320x800-01+2.jpg",
                    },
                    Header: {
                      S: "",
                    },
                    LinkTo: {
                      S: "financing/synchrony",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/asset_1320x800-01+3.jpg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "5 Star Review Image",
                    },
                    Caption: {
                      S: "Here's what our satisfied customers have to say",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-5-star.jpg",
                    },
                    Header: {
                      S: "OUR HAPPY CUSTOMERS",
                    },
                    LinkTo: {
                      S: "about-us/happy-customers",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/home-hero-5-star-1-550x550.jpg",
                    },
                    TextColor: {
                      S: "floralwhite",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "People building a house",
                    },
                    Caption: {
                      S: "We want to better our community by supporting some amazing organizations!",
                    },
                    DesktopUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/habitat-test.jpg",
                    },
                    HasBackground: {
                      BOOL: true,
                    },
                    Header: {
                      S: "GIVING BACK",
                    },
                    LinkTo: {
                      S: "/about-us/giving-back",
                    },
                    MobileUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/habitat-test-550x550.jpg",
                    },
                    TextColor: {
                      S: "black",
                    },
                  },
                },
              ],
            },
            MobileMeetTheTeamImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0182+3.jpeg",
            },
            Name: {
              S: "Home",
            },
            QuoteIconName: {
              S: "house-heart",
            },
          },
        },
        {
          M: {
            Alt: {
              S: "A hand cleaning wood with a towel",
            },
            DesktopImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/care-and-maintenance+3.jpg",
            },
            MobileImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/care-and-maintenance+2.jpg",
            },
            Name: {
              S: "CareMaintenance",
            },
          },
        },
        {
          M: {
            Name: {
              S: "FAQs",
            },
            Questions: {
              L: [
                {
                  M: {
                    A: {
                      S: "If there is available sidewalk (closest) please feel free to use it. If not, there’s sometimes available sidewalk parking on the side street across from Lamps Plus. Last but not least we do have a parking lot that is accessible from the alley that is behind the building. There are signs on the block wall indicating the parking spaces that belong to us. There is no access from upstairs to downstairs, you will have to walk down the sidewalk (Vega St) to West Morena Blvd and then hang a left.",
                    },
                    Alt: {
                      S: "Flooring Creations Storefront",
                    },
                    ImageUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/9687B49B-07A5-447B-BF02-AC4C8992D79F.jpeg",
                    },
                    Q: {
                      S: "Where should I park?",
                    },
                  },
                },
                {
                  M: {
                    A: {
                      S: "Flooring Creations, Inc. DOES NOT offer labor only or repair services. Our unique business model is one that is built to help you find your new floor and if needed we can recommend a very good installer. We strongly recommend that you read our great reviews on Yelp and Google to confirm this.",
                    },
                    IconName: {
                      S: "hammer",
                    },
                    Q: {
                      S: "How much is it just for labor?",
                    },
                  },
                },
                {
                  M: {
                    A: {
                      S: "We do not offer this service of sanding and refinishing wood floors. May we recommend Walter at Atlas Flooring (619-299-9695), or Ismael at IR Hardwood (619-917-5788). You can find both of these companies on Yelp or Google.",
                    },
                    IconName: {
                      S: "arrow-clockwise",
                    },
                    Q: {
                      S: "Do You Refinish Existing Wood Floors?",
                    },
                  },
                },
                {
                  M: {
                    A: {
                      S: "To set up an appointment at your home for a consultation and measure. Please call the store at 619-275-5338 and just say that you would like to set up an appointment with one of the Gus’s. There is a $50 to $100 measure fee applicable. These monies are refunded back to you 100% on your materials purchase. This is money well spent if you have a lot of questions about the impact (decor & installation) of a flooring replacement in your home. Not comfortable paying for a measure, well then we strongly recommend a visit to our beautiful and centrally located showroom.",
                    },
                    IconName: {
                      S: "calendar-week",
                    },
                    Q: {
                      S: "Do you guys do Free Estimates or Consultations?",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Employees: {
              L: [
                {
                  M: {
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0163.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0163+2.jpeg",
                    },
                    Name: {
                      S: "Gus Morgutia",
                    },
                    Paragraphs: {
                      L: [
                        {
                          S: "Flooring Creations, Inc. was founded by Gustavo Morgutia Sr, Gus Sr. as everybody knows him started in flooring at the young age of 16 as a helper on a carpet crew. This coincidence led to several ventures within the flooring industry, each bringing him closer to his goal of owning his own retail store.",
                        },
                        {
                          S: "37 years later Flooring Creations, Inc. has the experience and culture of its founder. This experience covers vast product knowledge, installation, decor, and most importantly a genuine passion for helping customers instead of trying to sell them.",
                        },
                      ],
                    },
                    Quote: {
                      M: {
                        Index: {
                          N: "2",
                        },
                        Text: {
                          S: "I never realized that my job was so simple, once I learned to help people everything else just seemed to fall into place. Our company culture & philosophy is: We are in the business of helping people create beautiful homes, and beautiful homes begin with beautiful floors.",
                        },
                      },
                    },
                    Title: {
                      S: "Flooring Creations Owner",
                    },
                  },
                },
                {
                  M: {
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0003+2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0003+2.jpg",
                    },
                    Name: {
                      S: "Gus Morgutia (Junior)",
                    },
                    Paragraphs: {
                      L: [
                        {
                          S: "Gus Junior is a chip off the old block, the apple did not fall far from the tree. He easily adopted his father's company culture of helping customers versus trying to sell them. He has been working with his father since he was a kid. Now in his 30s, he has roughly 10 years of experience helping people find flooring for their homes.",
                        },
                      ],
                    },
                    Title: {
                      S: "Sales/Assistant Store Manager",
                    },
                  },
                },
                {
                  M: {
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0104+2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0104+2.jpg",
                    },
                    Name: {
                      S: "Stephanie Rodriguez",
                    },
                    Paragraphs: {
                      L: [
                        {
                          S: "Stephanie is a great compliment to the Gus’s. She helps on the administrative side to make sure that the operations of the business are running smoothly so our customers receive the best possible customer service.",
                        },
                      ],
                    },
                    Title: {
                      S: "Administration/Operations",
                    },
                  },
                },
              ],
            },
            Name: {
              S: "AboutUs",
            },
            TeamImgAlt: {
              S: "Flooring Creations Employees",
            },
            TeamImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0182+2.jpeg",
            },
          },
        },
        {
          M: {
            Name: {
              S: "GivingBack",
            },
            OrganizationImgs: {
              L: [
                {
                  S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/habitat-for-humanity+(1).jpg",
                },
                {
                  S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/tecolote-youth-baseball.jpg",
                },
                {
                  S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/sd-humane-society.jpg",
                },
                {
                  S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/presidio-little-league.jpg",
                },
              ],
            },
          },
        },
        {
          M: {
            Name: {
              S: "HappyCustomers",
            },
            Reviews: {
              L: [
                {
                  M: {
                    City: {
                      S: "San Diego, CA",
                    },
                    Content: {
                      S: "This is our second time working with Flooring Creations and I cannot say enough good things about this family owned business. They have a fantastic selection of high quality materials at a good price. The team is very knowledgeable and had great suggestions now only for what flooring we should select but also design ideas and solutions to a problem I had with my stairs. Gus and his team are responsive, professional and friendly. We will be back for any future flooring needs!",
                    },
                    Date: {
                      S: "12/30/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Allison W.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "Mission Valley, San Diego, CA",
                    },
                    Content: {
                      S: "Very professional and knowledgeable. Never felt pressure to purchase anything and was shown all of my options for my carpet install. Very happy with the carpet that was installed and will be back in the future.",
                    },
                    Date: {
                      S: "12/17/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Dylan V.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "We were very fortunate to choose Flooring Creations for the purchase of our luxury vinyl plank.  Gus was extremely knowledgeable and helpful, guiding us through the process.  We are very happy with our purchase and highly recommend Gus and Flooring Creations.",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "David I.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "I was remodeling a bathroom in my home when I did some research and liked what I read about this company here on yelp. The reviews were not kidding, the Gus’s were a huge help. The showroom had these really nice shower vignettes that gave me all the inspiration that I needed. They recommended a really good tile setter who was very professional and very talented. I am so happy with my new bathroom, thank you very much to Flooring Creations and their great staff. I will definitely be doing business with them again and recommending them to all of my friends and family.",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "Stephanie N.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "La Mesa, CA",
                    },
                    Content: {
                      S: "The flooring creations staff are very knowledgeable about floors and helped us make a selection that works for us.  We were doing a full remodel on our house and Gus gave us advice on how best to compare our flooring sample with our other materials samples.  They let us check out the sample for a couple of days to look at it in our house and were kind enough to let us do a second check out when we were choosing cabinet materials.  They had a great showroom with a great range of samples, including large samples on the floor of the more popular styles.  The pricing was great, and better than other companies who quoted us.\n\nGus was always very responsive, and quick to answer questions.  They recommended installers, and the installer were incredibly competent, fast, courteous, and tidy.  \n\nI'm very happy with the product, pricing, service and couldn't be happier with the result.",
                    },
                    Date: {
                      S: "9/14/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Jess I.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "Spring Valley, CA",
                    },
                    Content: {
                      S: "My wife and I were looking for flooring for a kitchen remodel. From the moment we walked into the store we got excellent vibes. Gus is awesome and completely took care of us helping find the right floor within our budget. Absolutely loved that we could take back samples (large samples too, 2x3 feet or so, not the small piece sample you get from other places) home to see how it would look in our house (we found several options that we liked and couldn't decide). No pressure, no rush at all from Gus and the team.  He took the time to explain all the differences in the numerous options they have in store and answer every question we had. Even after we left Gus was always available by text, phone or email to answer any questions we had or forgot to ask (and he was quick to respond too). Really can't say enough great things about the experience we had with Gus and Flooring Creations. They are a family owned business also so we definitely felt the more personal connection and attention you get compared to a chain store. We'll be going back to Gus and team the next time we need flooring.",
                    },
                    Date: {
                      S: "9/12/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Hector U.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "Gus has a keen eye for design with attention to detail! I tend to be challenged bringing my designs all together. Gus walked us through each step, explained what would work with great detail & expertise which put us at ease. He communicated with us regularly to ensure we were on the same page & on schedule. Gus referred us to an amazing installer which we would use again if the need would arise. Each bathroom has its own design and we absolutely LOVE the job Flooring Creations did for us!!!",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "Brenda T.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "We were very pleased with the work Gus Jr and his team did for us. They were reasonably priced, professional, paid attention to detail and offered options when decisions needed to be made or we ran into a challenge. We would definitely recommend them!",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "Scott J.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "Dallas, TX",
                    },
                    Content: {
                      S: "Recently purchased a condo and wanted to update the flooring in the new place. Based on great reviews here on Yelp I contacted Gus at Flooring Creations who answered my inquiry almost immediately. I briefly explained my situation and Gus went right to work. The same day we spoke, Gus made time to meet me at the new place and go over multiple flooring options. Gus took measurements, photos and notes and the next day provided me with different options in different price ranges so I could make the choice on my own. Gus provided excellent expertise and allowed me to make the choice on my flooring with no pressure. Gus and the Flooring Creations team got everything in order and even worked with me on installation when there were some delays in renovations at my place. The installers they suggested did an excellent job and Gus checked in after to make sure I was happy with the finished product. If you need new flooring please contact Gus at Flooring Creations, you won't be disappointed. Highly recommended!",
                    },
                    Date: {
                      S: "8/4/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Scott R.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "Oceanside, CA",
                    },
                    Content: {
                      S: "We moved to a house with dark brown laminate floors and wanted to replace them with something lighter and easy care. Based on the positive reviews, we went to Flooring Creations and were very pleased with their help and knowledge. Gus spent time with us to ensure we found the perfect flooring to meet our needs. The installation is complete and we are very pleased with the results. We ended up with a luxury vinyl plank and feel like the pricing was very good.\nGus was very helpful in recommending tradesmen and installers to refinish our stairs and move vanities to replace the flooring under them. The installers were amazing! The demolition was challenging and the results are worth it. Their attention to detail was impressive.",
                    },
                    Date: {
                      S: "7/20/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Jeff L.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "Fantastic experience.  These folks know their stuff!! Glad we took the time to vist their show room!! Thanks Gus and Gus JR!!",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "Roy T.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "",
                    },
                    Content: {
                      S: "We had previously made a purchase from Flooring Creations several years ago, and the service was excellent, so returned last month to make another carpet purchase.\n\nAgain the service experience with Flooring Creations was excellent,\nwe are pleased with the quality and appearance of our Mohawk carpet purchase and the pricing provided a good buy.\n\nFrom start to finish the whole process went very smoothly and it is a pleasure to do business with Flooring Creations..\n\nWe felt very comfortable working with the Gus and Gus Jr. Thru a series of questions we were guided to a carpet selection that we felt was right for our needs.\n\nThe carpet installer recommended was a pleasure to work with; polite, professional, completed the installation as scheduled, while at the same time minimizing the disruption in our lives.\n\nWe recommend Flooring Creation for excellent service and a great selection of quality flooring products.",
                    },
                    Date: {
                      S: "",
                    },
                    IconName: {
                      S: "google",
                    },
                    Name: {
                      S: "John & Bonnie C.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "San Diego, CA",
                    },
                    Content: {
                      S: "I was looking for tile transitions and had been having problems finding someone to do it. Went into the store, spoke with Gus and was pleased to see how knowledgeable and passionate he was about flooring.\n\nHe also recommended a great installer who did a very professional job. Everything went smoothly and I am very pleased with my new kitchen and bathroom floors.",
                    },
                    Date: {
                      S: "5/24/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Samuel L.",
                    },
                  },
                },
                {
                  M: {
                    City: {
                      S: "San Diego, CA",
                    },
                    Content: {
                      S: "Just had our floors done. Excellent experience with Flooring Creations. Knowledgeable, professional staff with excellent customer service. Explained everything so we knew exactly what to expect. Very happy with our new floors. Would highly recommend them or use them again for another project.",
                    },
                    Date: {
                      S: "5/11/2022",
                    },
                    IconName: {
                      S: "yelp",
                    },
                    Name: {
                      S: "Henry F.",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Address: {
              S: "1275 West Morena Blvd. San Diego, CA 92110",
            },
            ContactCaption: {
              S: "Get in touch to schedule an appointment, or just to say hi!",
            },
            ContactSubCaption: {
              S: "There is a $50 to $100 measure fee applicable (depending on location and type of project).. This fee is refunded back to you 100% on your materials purchase. This is money well spent if you have a lot of questions about the decor and installation of a flooring project. Not comfortable paying for a measure? Then we strongly recommend a visit to our beautiful and centrally located showroom.",
            },
            IconName: {
              S: "send",
            },
            Name: {
              S: "Contact",
            },
            ParkingGoogleImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/9687B49B-07A5-447B-BF02-AC4C8992D79F.jpeg",
            },
            ParkingSignImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/rear-parking-sign.jpg",
            },
            RearParkingImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/rear-parking.jpg",
            },
            StoreFrontImgUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/contact-page-header.jpg",
            },
          },
        },
        {
          M: {
            MainContent: {
              S: "No matter how big or small of a project, Flooring Creations has a financing plan that’s right for you. Get ready to realize your dream home today with the financing card that can be used for everything for your home at millions of locations, nationwide.  Call Flooring Creations or come in to see what special financing is being offered at the moment.",
            },
            MainImg: {
              M: {
                Alt: {
                  S: "Financing Application Image",
                },
                Src: {
                  S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/asset_1320x800-01.jpg",
                },
              },
            },
          },
        },
      ],
    },
    Products: {
      L: [
        {
          M: {
            CareMaintenanceSections: {
              L: [
                {
                  M: {
                    BulletPoints: {
                      L: [
                        {
                          S: "Sweep or Vacuum (same vacuum requirements as wood floors) the floor. Then proceed to clean it by mopping (wet mop fine) or using an over the counter cleaner (no wax) that is rated for vinyl floor.",
                        },
                        {
                          S: "Lift the furniture to move it, avoid dragging. The legs of furniture should have soft peal & stick felt pad on the legs & & bases to prevent scratching as the furniture moves around with use. Take special caution when moving very heavy objects across wood & laminate ﬂoors; it is preferable not to drag things on the wood or laminate, but if one must then lay down a blanket or other soft item that will slide easily for the item to rest on. Also if the piece of furniture has adequate felt pad on it, then you can slide it carefully.",
                        },
                      ],
                    },
                  },
                },
              ],
            },
            Description: {
              S: "This floor will give you the beauty of wood flooring without the sensitivity to water or pet abuse.",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/gRPNP25s+2.jpeg",
            },
            LinkTo: {
              S: "/products-services/luxury-vinyl-plank",
            },
            Name: {
              S: "Luxury Vinyl Plank",
            },
            NavBarClass: {
              S: "luxuryVinylPlankLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Living room and kitchen with vinyl flooring",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/luxury-vinyl-header.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/luxury-vinyl-header+2.jpg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Luxury Vinyl Plank also known as LVP (wood look) or LVT (tile look) has taken the market by storm. One of the reasons this flooring has become so popular is it really checks all the boxes. It is made out of ingredients that are 100% water and pet-proof, so consumers are really loving the fact that they don’t have to worry about those abuses. If you purchase a quality Luxury Vinyl Plank you will get very authentic wood or tile-looking details combined with quality ingredients that will give you a floor that can take the abuse that you and your family along with the pets will give it for the next 20 to 30 years.",
                        },
                        {
                          S: "Flooring Creations offers an expansive variety of beautiful high-quality Luxury Vinyl Plank. Here at Flooring Creations, our main goal is to make sure our customers have a pleasant and satisfactory experience from the stressful task of selecting a new Luxury Vinyl Plank floor. For the absolute best experience (much better than online) please visit the famous Gus’s (father & son) at our beautiful showroom. They will help you select the perfect color and style of Luxury Vinyl Plank that will compliment your current or new decor, when it’s all done you will absolutely love it.",
                        },
                        {
                          S: "Our showroom LVP area has a great selection as well as the most attractive colors showcased on the floor.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "LVP Flooring Samples",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0203.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0203.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Inspiration",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Visit our Pinterest page to see additional pictures for your inspiration needs.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Link: {
                      S: "https://www.pinterest.com/floorcreations/luxury-vinyl-plank-floor-inspiration/",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Piano on luxury vinyl plank flooring",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+4.16.03+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+4.16.03+PM.png",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for quality Luxury Vinyl Plank, which will run between $3.99 – $5.99 per square foot depending on the thickness of the wear layer, the overall thickness of product, and visual details. Labor to install Luxury Vinyl Plank can run between $2.50-$3.25 per square foot. A good estimating formula is to multiply your rough square feet by $10.00 per square foot. This formula will allow for a quality piece of Luxury Vinyl Plank along with professional install and basic demo & disposal of carpet.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Unlike the big box stores that sell you those small 6 x 6 inch sample swatches (useless). We understand how difficult it is to visualize your new floor in your home. So we purposely have very large samples in our showroom that we will loan (conditions apply) you to take home. This should give you the visualization that you need and the confidence as well to pull the trigger.",
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            CareMaintenanceSections: {
              L: [
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "A regular maintenance program is of the most importance for extending and maximizing the life and appearance of your new carpet. Please make sure to read the manufacturer guidelines.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    BulletPoints: {
                      L: [
                        {
                          S: "The most important part is to vacuum areas often that receive the most traffic, such as hallways, stairs, and exterior entryways in the home. Ideally, vacuum all the floor coverings a minimum of once a week, and the high-traffic areas more often, based on the usage. If you have pets, you may need to vacuum some areas daily. Removing loose soil while it remains on the carpet’s surface is important so that it is not walked into the carpet pile.",
                        },
                        {
                          S: "Remove spots as soon as something is spilled or tracked on the carpet. If spills or soil are allowed to remain, they may become permanent. Blot liquids with a dry, white, absorbent cloth or white paper towels. Do not scrub the area! Scrubbing can cause pile distortion in the affected area. Continue to use a dry cloth or paper towels until the area is completely dry.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    ParagraphWithLink: {
                      L: [
                        {
                          S: "Check out the ",
                        },
                        {
                          S: "<Link=https://carpet-rug.org/carpet-for-homes/cleaning-and-maintenance/>Carpet Rug Institute’s website for more in depth carpet cleaning information!",
                        },
                      ],
                    },
                  },
                },
              ],
            },
            Description: {
              S: "Comfortable, warm, cozy, sound-absorbing, stain-resistant fibers and finishes that are ideal for high-traffic homes and pet owners",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Exquisite+Portrait.jpeg",
            },
            LinkTo: {
              S: "/products-services/carpet",
            },
            Name: {
              S: "Carpet",
            },
            NavBarClass: {
              S: "carpetLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Baby sitting on carpet",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/carpet-header-2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/carpet-header-2+2.jpg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Carpets have been around forever and will always be around. The benefits that it brings to your home are comfort, insulation, and noise mitigation between two floors of your home. Flooring Creations offers an expansive variety of beautiful high-quality carpets. Here at Flooring Creations, our main goal is to make sure our customers have a pleasant and satisfactory experience from the stressful task of selecting a new carpet. For the absolute best experience (much better than online) please visit the famous Gus’s (father & son) at our beautiful showroom. They will help you select the perfect color and style of carpet that will compliment your current or new decor, when it’s all done you will absolutely love it.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "People and dog on carpet",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/28707_756_campaign_03+2.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/28707_756_campaign_03+2.png",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Carpet Performance",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "You must take a few minutes to see this video! It shows how strong our Mohawk SmartStrand carpet is!",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    IframeSrc: {
                      S: "https://www.youtube.com/embed/JCqmYF0J0gg?feature=oembed",
                    },
                    VideoTitle: {
                      S: "Carpet Video",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Our showroom carpet area featuring Mohawk SmartStrand carpets in a nice array of styles & colors",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Flooring Samples",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0247.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0247.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Top Brands",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Brand Names",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screen+Shot+2022-09-25+at+8.46.58+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/smart-strand-silk.jpg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Brand Names",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/smart-strand-all-pet.jpg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Brand Names",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/dream-weaver.jpg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Carpet Brand Names",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/dream-weaver-lifetime.jpg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for quality carpet, which will run between $2.99 (polyester) to $4.99 (SmartStrand) per square foot depending on the type of yarn (most important), density, weight (oz’s) and style. High-quality pad (very important) and labor can run between $1.50 - $2.00 per square foot. A good estimating formula is to multiply your rough square feet by $6.00 per square foot. This formula will allow for a quality piece of carpet along with professional install and basic demo & disposal of carpet.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "We understand how difficult it is to visualize your new floor in your home. So we will order carpet samples selected from our store displays. These samples will arrive directly at your home (no charge) within 5-6 days. This should give you the visualization that you need and the confidence as well to pull the trigger.",
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Description: {
              S: "Porcelain tile is an incredible product that can be used for any floor or wall application. It is the only floor that is indestructible!",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+3.55.54+PM.png",
            },
            LinkTo: {
              S: "/products-services/porcelain-tile",
            },
            Name: {
              S: "Porcelain Tile",
            },
            NavBarClass: {
              S: "stoneTileLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Bathroom with tile flooring and walls",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/IMG_2287+2.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/IMG_2287+3.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Besides offering an expansive variety of beautiful high-quality Porcelain Tiles. Here at Flooring Creations, our main goal is to make sure our customers have a pleasant and satisfactory experience from the stressful task of selecting a new Porcelain tile for your floor or shower walls. For the absolute best experience (much better than online) please visit the famous Gus’s (father & son) at our beautiful showroom. They will help you select the perfect color and style of Porcelain tile that will compliment your current or new decor, when it’s all done you will absolutely love it.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Array of Styles & Colors",
                    },
                  },
                },
                {
                  M: {
                    BulletPoints: {
                      L: [
                        {
                          S: "Styles: Traditional, Rustic, and Modern.",
                        },
                        {
                          S: "Type of Tile: Porcelain and some Ceramic (no natural stone) in the most contemporary sizes of 12 x 24, 24 x 24, and 24 x 48 inches. We also offer mosaics that are perfect for accents, backsplashes, or fireplace faces.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Inspiration",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Visit our Pinterest page to see additional pictures for your inspiration needs.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Link: {
                      S: "https://pin.it/7kQRK8U",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Bathroom with tile shower and tub walls",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/24+X+48+Savoy+-+Azula+3.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/24+X+48+Savoy+-+Azula+2.jpg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for quality Porcelain tile, which will run between $3.99 – $6.99 per square foot depending on the quality of detail and origin of the Porcelain tile. Labor to install Porcelain tile can run between $3.75-$4.75 per square foot. A good estimating formula is to multiply your rough square feet by $12.00 per square foot. This formula will allow for a quality piece of Porcelain tile and adhesive (super important) along with professional install and basic demo & disposal of carpet.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Unlike the big box stores that sell you those small 6 x 6 inch sample swatches (useless). We understand how difficult it is to visualize your new floor in your home. So we purposely have very large samples in our showroom that we will loan (conditions apply) you to take home. This should give you the visualization that you need and the confidence as well to pull the trigger.",
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Description: {
              S: "Nothing rivals the way wood floors warm up a room with their timeless and classic good looks",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/TP3sdWJA+2.jpeg",
            },
            LinkTo: {
              S: "/products-services/wood",
            },
            Name: {
              S: "Wood",
            },
            NavBarClass: {
              S: "woodLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Living room with wood floors",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+4.31.35+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Miro+LCKL874+3.jpg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Besides offering an expansive variety of natural, beautiful, and high-quality wood floors. Here at Flooring Creations, our main goal is to make sure our customers have a pleasant and satisfactory experience from the stressful task of selecting a new wood floor. For the best absolute experience (much better than online) please visit the famous Gus’s (father & son) at our beautiful showroom. They will help you select the perfect color and style of wood that will compliment your current or new decor, when it’s all done you will absolutely love it.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Array of Styles & Colors",
                    },
                  },
                },
                {
                  M: {
                    BulletPoints: {
                      L: [
                        {
                          S: "Styles: Traditional, Rustic and Exotic.",
                        },
                        {
                          S: "Species: European Oak, Exotics from South America, Domestics such as Hickory & Maple and Asian wood such as Acacia.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Kitchen with wood floors",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+4.31.52+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+4.31.52+PM.png",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Our showroom Wood Flooring area has a great selection as well as the most attractive colors showcased on the floor.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Wood Flooring Samples",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0210.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0210.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Inspiration",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Visit our Pinterest page to see additional pictures for your inspiration needs.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Link: {
                      S: "https://www.pinterest.com/floorcreations/wood-floor-inspiration/",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for a quality engineered wood floor, which will run between $5.99 – $9.99 per square foot depending on the mm’s of thickness for the actual wood layer (1.5 to 4 mm). Labor to install wood can run between $3.75-$4.75 per square foot. A good estimating formula is to multiply your rough square feet by $14.00 per square foot. This formula will allow for a quality piece of wood and adhesive (super important) along with professional install and basic demo & disposal of carpet.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Wood layers explanation",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screen+Shot+2022-10-27+at+5.27.51+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screen+Shot+2022-10-27+at+5.27.51+PM.png",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Unlike the big box stores that sell you those small 6 x 6 inch sample swatches (useless). We understand how difficult it is to visualize your new floor in your home. So we purposely have very large samples in our showroom that we will loan (conditions apply) you to take home. This should give you the visualization that you need and the confidence as well to pull the trigger.",
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Description: {
              S: "New countertops will put that finishing touch on your beautiful new kitchen!",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-02+at+3.59.49+PM.jpeg",
            },
            LinkTo: {
              S: "/products-services/countertops",
            },
            Name: {
              S: "Countertops",
            },
            NavBarClass: {
              S: "quartzCountersLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Kitchen countertop",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/countertops-header-v2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/countertops-header-v2+2.jpg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Countertops are available in a wide range of materials, such as Marble, Granite, Quartzite (natural stone), and Quartz (synthetic). Flooring Creations offers a variety of beautiful high-quality countertops, specializing in quartz and quartzite only. Here at Flooring Creations, our main goal is to make sure our customers have a pleasant and satisfactory experience from the stressful task of selecting a new countertop. For the absolute best experience (much better than online) please visit the famous Gus’s (father & son) at our beautiful showroom. They will help you select the perfect color and style of countertop that will compliment your current or new decor, when it’s all done you will absolutely love it.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Inspiration",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Visit our Pinterest page to see additional pictures for your inspiration needs.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Link: {
                      S: "https://www.pinterest.com/floorcreations/counter-top-inspiration/",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for a quality countertop, which will run between $2,000 to $4,000 per slab (avg slab = 55 sqft) depending on the type of slab. Quartzite will be more expensive than synthetic quartz as it is a very hard natural stone. An average size kitchen can easily take two slabs. Labor, also known as fabrication, is charged by the square foot and an average price is $45 per square foot for the countertop space only.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "We understand how difficult it is to visualize your new countertop. So we strongly advise a visit to the store or slab yard (must make an appointment for slab yard). We also advise that you bring with you some elements. These elements would be a paint sample, a cabinet door or drawer, and a sample of the flooring. These elements allow you to be as effective as a designer when using them. In the store, we will allow you to check out a sample (conditions apply) and at the slab yard (better experience) you will be able to view the entire slab.",
                        },
                        {
                          S: "Quartz Countertops are available in a wide range of patterns, including realistic marble-looks, modern concrete-looks, and nature-inspired looks. Quartz countertops colors range from icy white tones to midnight black tones.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Countertop sample",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-01+at+5.11.25+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-01+at+5.11.25+PM.png",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Quartzite countertops are available in a wide range of color and natural patterns (mother nature at its finest). Quartzite is a very hard metamorphic rock that originated as sandstone. Through a process of high heating and pressurization sandstone is transformed into Quartzite, an extremely strong and durable natural stone. When heated, individual quartz pieces recrystallize giving it a beautiful and decorative sparkling pattern. Quartzite is ideal for any countertop surface due to its strength and long-lasting composition. Consider quartzite countertops for its strength, beauty, and overall durability.",
                        },
                        {
                          S: "Quartzite by mother nature, no competition with synthetic quartz.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Kitchen island countertop",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-01+at+5.13.39+PM.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Screenshot+2022-11-01+at+5.13.39+PM.png",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Description: {
              S: "A beautiful bathroom tile design can transform a plain space into a standout sanctuary",
            },
            ImageUrl: {
              S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/Porcelain+Tile+24+x+48+inch+jumbo+tiles_+4.jpg",
            },
            LinkTo: {
              S: "/products-services/shower-walls",
            },
            Name: {
              S: "Shower & Tub Walls",
            },
            NavBarClass: {
              S: "showerWallsLink",
            },
            ProductSections: {
              L: [
                {
                  M: {
                    Alt: {
                      S: "Shower tile walls",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/52%2B+Walk+in+Shower+Design+(+STEP+IN+)+Large+Doorless+Showers+2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/52%2B+Walk+in+Shower+Design+(+STEP+IN+)+Large+Doorless+Showers+3.jpg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "At Flooring Creations we believe that your bathroom is a very important part of your home. It is the part of your home where you get freshened and gussied up (pun intended). If your bathroom is dated and unsatisfying to look at. Come down to Flooring Creations and let the famous Gus’s help you select new tile to turn your existing bathroom into a sanctuary. We even have custom shower vignettes built to exact size, to help you visualize how beautiful and functional your shower can be. We will help you select a tile combination that will make your bathroom worthy of a magazine shoot.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Shower and bathtub with tile walls",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/IMG_2330.jpeg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/IMG_2330.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "To help you visualize your dream bath we have built to code three beautiful custom showers; these vignettes are definitely worth the trip to the showroom.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Display Showers",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/image.png",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0218.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Display Showers",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/0243.jpeg",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Inspiration",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Visit our Pinterest page to see additional pictures for your inspiration needs.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Link: {
                      S: "https://www.pinterest.com/floorcreations/bathshower-inspiration/",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Pricing",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "For ballpark purposes here is a good ballpark for quality Porcelain tile and an accent material (mosaic). Porcelain tile will run between $3.99 – $6.99 per square foot depending on the quality of detail and origin of the Porcelain tile. The accent material (mosaic) can range from $15.00 to $30.00 per square foot depending on what the mosaic is made of and what blend of materials (natural stone, glass, etc..).",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "Do you guys loan or give out Samples?",
                    },
                  },
                },
                {
                  M: {
                    Paragraphs: {
                      L: [
                        {
                          S: "Unlike the big box stores that sell you those small 6 x 6 inch sample swatches (useless). We understand how difficult it is to visualize your new floor in your home. So we purposely have very large samples in our showroom that we will loan (conditions apply) you to take home. This should give you the visualization that you need and the confidence as well to pull the trigger.",
                        },
                      ],
                    },
                  },
                },
                {
                  M: {
                    Alt: {
                      S: "Shower tile walls",
                    },
                    DesktopImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/N-Broome+Marble+Look+Tile+2.jpg",
                    },
                    MobileImgUrl: {
                      S: "https://flooringcreations-images.s3.us-west-1.amazonaws.com/N-Broome+Marble+Look+Tile+2.jpg",
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
    WebsiteColors: {
      M: {
        Background: {
          S: "floralwhite",
        },
        Google: {
          S: "#4285f4",
        },
        Primary: {
          S: "#d3c464",
        },
        Secondary: {
          S: "#dbac79",
        },
        Text: {
          S: "black",
        },
        TmpPrimary: {
          S: "#d3c464",
        },
        TmpSecondary: {
          S: "#dbac79",
        },
        Yelp: {
          S: "#ff1a1a",
        },
      },
    },
  };

  return AWS.DynamoDB.Converter.unmarshall(data);
};
