import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import "./Footer.css";

const Footer = ({ dbSettings, colors }) => {
  return (
    <div style={{ width: "100%" }} className="mx-auto border-top mt-5">
      <div
        style={{ maxWidth: "1600px" }}
        className="container footerContent mx-auto"
      >
        <footer className="row row-cols-1 row-cols-lg-4 pt-3 mx-3">
          <div id="footerLogo" className="col mb-3">
            <Link
              to="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                src={dbSettings.DesktopLogoUrl}
                className="img-fluid bi me-2"
                alt="Flooring Creations Logo"
              />
            </Link>
            <p className="text-muted" style={{ fontSize: "16px" }}>
              © 2024 Flooring Creations. All Rights Reserved
            </p>
          </div>

          <div className="col mb-3 footerContent">
            <h5>Hours</h5>
            <div className="flex-column text-muted">
              {dbSettings.HoursSections.map((section) => (
                <div
                  key={`hoursSection${dbSettings.HoursSections.indexOf(
                    section
                  )}`}
                  className="py-1"
                >
                  <p className="flex-column my-1 text-muted footerContent text-nowrap">
                    {section.DaysLabel}
                  </p>
                  <p className="flex-column my-1 text-muted footerContent text-nowrap">
                    {section.HoursLabel}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="border-top mb-3 footerSeparator"></div>

          <div className="col mb-3 footerContent">
            <h5>Contact</h5>
            <div className="flex-column text-muted">
              <div>
                <div className="footerIcon d-inline me-2 mb-3">
                  <Icon iconName="telephone" color={colors.Primary} />
                </div>
                <a
                  className="ms-1 nav-link d-inline footerContent p-0 text-muted text-nowrap mb-3"
                  href="tel:6192755338"
                >
                  (619) 275-5338
                </a>
              </div>
              <div>
                <div className="footerIcon d-inline me-2 mb-3">
                  <Icon iconName="envelope" color={colors.Primary} />
                </div>
                <Link
                  to="contact"
                  className="ms-1 nav-link d-inline footerContent p-0 text-muted text-nowrap mb-3"
                >
                  Email Us
                </Link>
              </div>
              <div>
                <div className="footerIcon d-inline me-2 mb-3">
                  <Icon iconName="geo-alt" color={colors.Primary} />
                </div>
                <div className="ms-1 nav-link d-inline footerContent p-0 text-muted mb-3">
                  <a
                    className="d-inline footerContent text-muted nav-link"
                    href="https://www.google.com/maps/place/1275+W+Morena+Blvd,+San+Diego,+CA+92110/@32.7699885,-117.2056993,17z/data=!3m1!4b1!4m5!3m4!1s0x80deaa937856991d:0xe3c58746fe6cbbbe!8m2!3d32.7699885!4d-117.2035106"
                    target="_blank"
                  >
                    1275 West Morena Blvd.
                  </a>
                  <a
                    className="d-inline-block footerContent text-muted nav-link"
                    href="https://www.google.com/maps/place/1275+W+Morena+Blvd,+San+Diego,+CA+92110/@32.7699885,-117.2056993,17z/data=!3m1!4b1!4m5!3m4!1s0x80deaa937856991d:0xe3c58746fe6cbbbe!8m2!3d32.7699885!4d-117.2035106"
                    target="_blank"
                  >
                    San Diego, CA 92110
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top mb-3 footerSeparator"></div>

          <div className="col mb-3">
            <h5>About Us</h5>
            <p className="flex-column text-muted" style={{ fontSize: "20px" }}>
              At Flooring Creations, our job is simple: We are here to HELP YOU
              find a product that will enhance the look of your home, and
              deliver value as well.
            </p>
          </div>

          <div id="footerLogoMobile" className="col">
            <Link
              to="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                src={dbSettings.DesktopLogoUrl}
                className="img-fluid mx-auto"
                alt="Flooring Creations Logo"
              />
            </Link>
            <p className="text-muted footerContent text-center">
              © 2024 Flooring Creations. All Rights Reserved
            </p>
          </div>
        </footer>
      </div>
      <div
        id="footerSocials"
        style={{ width: "100%" }}
        className="border-top py-3 text-center"
      >
        {dbSettings.Socials.map((social) => (
          <a
            key={`social${dbSettings.Socials.indexOf(social)}`}
            href={social.Url}
            target="_blank"
            className="d-inline mx-3"
          >
            <Icon iconName={social.IconName} color={colors.Secondary} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Footer;
