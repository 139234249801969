import React, { useEffect, useRef } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../../Utilities/Scroller.ts";
import { config, useSpring, animated } from "react-spring";
import Icon from "../../Icon";
import ContactForm from "./ContactForm/ContactForm";
import "./Contact.css";

const Contact = ({ dbSettings, products, colors }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("contactLink");
    document.title = "Contact - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetIconAnimation = () => {
    return useSpring({
      from: { opacity: 0, x: -500 },
      to: { opacity: 1, x: 0 },
      config: config.gentle,
    });
  };

  const GetImgAnimation = (num) => {
    return useSpring({
      from: { opacity: 0, y: 50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: 500 + (num * 500) / 3,
    });
  };

  const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 37.42216,
    lng: -122.08427,
  };

  return (
    <div className="mx-auto mt-3" style={{ maxWidth: "1600px" }}>
      <div className="row row-cols-1 row-cols-lg-2 mx-3 mx-lg-5">
        <div
          style={{ minHeight: "100%" }}
          className="col text-center px-3 px-lg-5 my-auto"
        >
          <animated.div style={GetIconAnimation()}>
            <Icon
              iconName={dbSettings.IconName}
              color={colors.Primary}
              size="10vh"
            />
          </animated.div>
          <p className="my-4 desktopParagraph">{dbSettings.ContactCaption}</p>
          <p className="my-3 my-lg-5 desktopParagraph">
            {dbSettings.ContactSubCaption}
          </p>
          <animated.img
            style={GetImgAnimation(0)}
            src={dbSettings.StoreFrontImgUrl}
            className="w-100 rounded-3 shadow desktopContactContent"
          />
          <animated.p
            style={GetImgAnimation(1)}
            className="my-4 desktopParagraph desktopContactContent"
          >
            {dbSettings.Address}
          </animated.p>
        </div>
        <div style={{ minHeight: "100%" }} className="col rounded-3 shadow">
          <ContactForm colors={colors} products={products} />
        </div>
        <div className="text-center mt-5">
          <animated.img
            style={GetImgAnimation(0)}
            src={dbSettings.StoreFrontImgUrl}
            className="w-100 rounded-3 shadow mobileContactContent"
          />
          <animated.p
            style={GetImgAnimation(1)}
            className="my-4 desktopParagraph mobileContactContent"
          >
            {dbSettings.Address}
          </animated.p>
        </div>
      </div>
      <div className="mt-5 mt-lg-3 mx-3 mx-md-5 row">
        <animated.iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.8395246108325!2d-117.20569298481817!3d32.76999238097414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80deaa937856991d%3A0xe3c58746fe6cbbbe!2s1275+W+Morena+Blvd%2C+San+Diego%2C+CA+92110!5e0!3m2!1sen!2sus!4v1491767963055"
          allowFullScreen=""
          className="rounded-3 shadow col-5 mx-auto px-0 my-auto desktopImgs"
          style={{ height: 325, ...GetImgAnimation(2) }}
        />
        <img
          className="mt-3 col-5 mx-auto rounded-3 shadow px-0 desktopImgs"
          src={dbSettings.ParkingGoogleImgUrl}
          alt="Flooring Creations storefront"
        />
        <animated.iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.8395246108325!2d-117.20569298481817!3d32.76999238097414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80deaa937856991d%3A0xe3c58746fe6cbbbe!2s1275+W+Morena+Blvd%2C+San+Diego%2C+CA+92110!5e0!3m2!1sen!2sus!4v1491767963055"
          allowFullScreen=""
          className="rounded-3 shadow my-auto mobileImgs p-0"
          style={{ height: 250, ...GetImgAnimation(2) }}
        />
        <img
          className="mt-4 me-3 rounded-3 shadow px-0 mobileImgs"
          src={dbSettings.ParkingGoogleImgUrl}
          alt="Flooring Creations storefront"
        />
      </div>
    </div>
  );
};

export default Contact;
