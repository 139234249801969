import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowUpCircle, ChevronUp, Exclamation } from "react-bootstrap-icons";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./About_Us/AboutUs";
import GivingBack from "./About_Us/Giving_Back/GivingBack";
import HappyCustomers from "./About_Us/Happy_Customers/HappyCustomers";
import CareMaintenance from "./Care_Maintenance/CareMaintenance";
import Contact from "./Contact/Contact";
import CookieConsentBanner from "./CookieConsentBanner";
import FAQs from "./FAQs/FAQs";
import Financing from "./Financing/Financing";
import Synchrony from "./Financing/Synchrony/Synchrony";
import Home from "./Home/Home";
import NotFound from "./NotFound/NotFound";
import Product from "./Products_Services/ProductPage/Product";
import ProductsServices from "./Products_Services/ProductsServices";
import ParkingVideo from "../../IMG_1826.mov";

const Body = ({
  dbSettings,
  products,
  colors,
  productsServicesDropdownIcon,
  productsServicesDropdownAction,
  aboutUsDropdownIcon,
  setAboutUsDropdownIcon,
}) => {
  const homeSettings = dbSettings[0];
  const careMaintenanceSettings = dbSettings[1];
  const faqsSettings = dbSettings[2];
  const aboutUsSettings = dbSettings[3];
  const givingBackSettings = dbSettings[4];
  const happyCustomersSettings = dbSettings[5];
  const contactSettings = dbSettings[6];
  const financingSettings = dbSettings[7];

  // const homeSettings = dbSettings.filter((x) => x.Name == "Home")[0];
  // const careMaintenanceSettings = dbSettings.filter(
  //   (x) => (x.Name = "CareMaintenance")
  // )[0];

  const productsServicesNestedDropdownId = "productsServicesDropdownMenu";
  const aboutUsNestedDropdownId = "aboutUsDropdownMenu";

  const nestedDropdownIds = [
    productsServicesNestedDropdownId,
    aboutUsNestedDropdownId,
  ];

  const productsServicesNestedDropdownIdMobile =
    "productsServicesDropdownMenuMobile";
  const aboutUsNestedDropdownIdMobile = "aboutUsDropdownMenuMobile";

  const nestedDropdownIdsMobile = [
    productsServicesNestedDropdownIdMobile,
    aboutUsNestedDropdownIdMobile,
  ];

  const [showDialog, setShowDialog] = useState(false);

  const parkingInfoClickHandler = useCallback(() => setShowDialog(true), []);

  const modalOnHide = useCallback(() => setShowDialog(false), []);

  return (
    <div
      // data-bs-toggle="offcanvas"
      // data-bs-target="#navbarNavDropdown"
      // aria-controls="navbarNavDropdown"
      onClick={() => {
        nestedDropdownIds.map((id) => {
          if (document.getElementById(id).classList.contains("d-block")) {
            document.getElementById(id).classList.remove("d-block");
          }
        });

        nestedDropdownIdsMobile.map((id) => {
          if (document.getElementById(id).classList.contains("d-block")) {
            document.getElementById(id).classList.remove("d-block");
          }
        });

        if (productsServicesDropdownIcon == "caret-up-fill") {
          productsServicesDropdownAction("caret-down-fill");
        }

        if (aboutUsDropdownIcon == "caret-up-fill") {
          setAboutUsDropdownIcon("caret-down-fill");
        }
      }}
    >
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              dbSettings={homeSettings}
              products={products}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/products-services"
          element={<ProductsServices products={products} colors={colors} />}
        />
        {products.map((product) => (
          <Route
            key={`product${products.indexOf(product)}`}
            exact
            path={product.LinkTo}
            element={<Product product={product} colors={colors} />}
          />
        ))}
        <Route
          exact
          path="/care-maintenance"
          element={
            <CareMaintenance
              dbSettings={careMaintenanceSettings}
              products={products.filter(
                (x) => x.CareMaintenanceSections != null
              )}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/faqs"
          element={<FAQs dbSettings={faqsSettings} colors={colors} />}
        />
        <Route
          exact
          path="/about-us"
          element={<AboutUs dbSettings={aboutUsSettings} colors={colors} />}
        />
        <Route
          exact
          path="/about-us/giving-back"
          element={<GivingBack dbSettings={givingBackSettings} />}
        />
        <Route
          exact
          path="/about-us/happy-customers"
          element={
            <HappyCustomers
              dbSettings={happyCustomersSettings}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <Contact
              dbSettings={contactSettings}
              products={products}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/financing"
          element={<Financing dbSettings={financingSettings} />}
        />
        <Route path="/financing/synchrony" element={<Synchrony />} />
        <Route exact path="*" element={<NotFound colors={colors} />} />
      </Routes>
      <button
        onClick={parkingInfoClickHandler}
        title="Click for Parking Info"
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          maxWidth: "35vw",
          borderColor: colors.Secondary,
          background: colors.Background,
          borderWidth: "2px",
          color: colors.Text,
          zIndex: 3,
        }}
        className="m-2 rounded-3 p-0"
      >
        <div
          id="floating-parking-btn"
          className="rounded-3 m-2"
          style={{ fontSize: "28px" }}
        >
          <ArrowUpCircle className="my-auto me-1" />
          Parking Info
        </div>
      </button>
      {showDialog && (
        <Modal
          style={{ background: "transparent" }}
          centered
          show
          onHide={modalOnHide}
          className="rounded-3"
        >
          <Modal.Header closeButton>
            <Modal.Title>Parking Info</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rounded-3 d-inline-grid">
            <h5>
              If you're going to visit the store, please watch the video below
              for an update on the city's construction in the surrounding area.
            </h5>
            <div className="d-inline-flex mx-auto">
              <video
                controls
                style={{
                  height: "60vh",
                  width: "100%",
                  borderStyle: "solid",
                  borderColor: colors.Primary,
                }}
              >
                <source src={ParkingVideo} />
              </video>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Body;
