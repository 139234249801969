import React, { useEffect } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../../Utilities/Scroller.ts";
import { config, useSpring, animated } from "react-spring";
import Icon from "../../Icon";
import { useNavigate } from "react-router-dom";

const CareMaintenance = ({ colors, products, dbSettings }) => {
  useEffect(() => {
    NavItemStatus.SetActiveLink("careMaintenanceLink");
    document.title = "Care & Maintenance - Flooring Creations";

    Scroller.ScrollToTop(false);

    const timer = setTimeout(() => {
      Scroller.ScrollToTop(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const GetAnimation = (delay) => {
    return useSpring({
      from: { opacity: 0, y: 100 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: delay,
    });
  };

  const navigate = useNavigate();

  return (
    <div className="mx-auto" style={{ maxWidth: "1200px" }}>
      <div className="shadow rounded-3 mx-3 mx-lg-5 py-4">
        <div className="text-center mt-4 mx-3 mx-lg-5">
          <div className="row my-3">
            <h1 className="siteHeader text-lg-start col-12 col-lg-10 my-auto">
              Care &amp; Maintenance
            </h1>
            <button
              className="btn p-3 rounded-pill col-12 col-lg-2 my-3 mx-auto"
              style={{
                backgroundColor: colors.Primary,
                maxWidth: "200px",
              }}
            >
              <a
                href="https://flooringcreations-images.s3.us-west-1.amazonaws.com/Flooring+Creations+Care+%26+Maintenance1.pdf"
                target="_blank"
                style={{ color: "black", textDecoration: "none" }}
              >
                <span className="me-3">
                  <Icon iconName="download" color="black" size="2.5vh" />
                </span>
                Download
              </a>
            </button>
          </div>
          <p className="desktopParagraph text-start">
            Please take the time to learn how to take care of your investment.
            By taking proper care and using preventative measures your new
            floors will perform and last as they were designed to.
          </p>
          <hr
            style={{
              borderTopWidth: "2px",
              opacity: "1",
              color: colors.Primary,
            }}
          />
          <animated.div style={GetAnimation(350)}>
            <h2 className="desktopCaption text-lg-start mt-4">
              Luxury Vinyl Plank (aka lvp or lvt):
            </h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                Your luxury vinyl plank flooring from Flooring Creations is not
                only beautiful, but it is also highly durable and water &amp;
                pet proof. It only takes a few simple care and maintenance steps
                to keep your luxury vinyl plank floor looking beautiful for
                years to come.
              </p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Sweep, Vacuum (hard surface vacuum) or Dust Mop your floor to
                  remove all of the loose grit &amp; dirt.
                </li>
                <li className="desktopParagraph my-4">
                  Pick your poison, luxury vinyl plank has a very strong
                  protective coating allowing you to use most cleaners. Please
                  STAY AWAY from any cleaner claiming to make the floor shinier.
                  These cleaners contain wax as an additive that will stick to
                  your floor and cause dirt &amp; soil to stick to the floor,
                  making it almost impossible to remove (degreaser will remove).
                </li>
                <li className="desktopParagraph my-4">
                  You can select any brand of hard surface floor cleaner (no
                  wax-no shine) or you can make your own with apple cider
                  vinegar. The acidity in the vinegar helps remove dirt and
                  grime without leaving a buildup of soap or wax. Simply mix one
                  cup of cider vinegar with a gallon of hot water and use a
                  very, very damp mop to clean, rinsing the mop frequently with
                  hot water (hot as possible) to prevent dirt residue from being
                  spread.
                </li>
              </ul>
            </div>
            <h2 className="desktopCaption text-lg-start mt-4">
              Tips to Help LVP Flooring Last Longer
            </h2>
            <div className="text-start desktopParagraph my-4">
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Place a doormat or throw rug at every entrance to catch grit
                  and dirt that can damage (dull) floors.
                </li>
                <li className="desktopParagraph my-4">
                  VERY IMPORTANT! Prevent dents &amp; scratches from furniture
                  by installing felt pads (peel &amp; stick) on ALL furniture.
                  Heavily used furniture such as chairs and barstools will need
                  the felt pads replaced at least once or twice per year. We
                  recommend you purchase felt pads in all shapes and sizes to
                  accommodate the variety of furniture in your home.
                </li>
                <li className="desktopParagraph my-4">
                  Rolling chairs with plastic wheels will dull &amp; scratch the
                  finish overtime. To prevent this please use chairs with rubber
                  wheels or use chairs with no wheels and add felt pads to the
                  legs.
                </li>
              </ul>
            </div>
            <hr
              style={{
                borderTopWidth: "2px",
                opacity: "1",
                color: colors.Primary,
              }}
            />
          </animated.div>

          <animated.div style={GetAnimation(550)}>
            <h2 className="desktopCaption text-lg-start mt-4">
              Engineered Wood:
            </h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                Your beautiful new engineered wood floors from Flooring
                Creations can maintain their strength and beauty for years,
                while your flooring pairs the beauty of natural wood with easy,
                carefree maintenance. You'll get years of beautiful performance
                from your wood floors with a little preparation and know-how.
              </p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Sweep, Vacuum (hard surface vacuum) or Dust Mop your floor to
                  remove all of the loose grit &amp; dirt.
                </li>
                <li className="desktopParagraph my-4">
                  Pick your poison, pre-finished engineered wood floors have a
                  very strong protective coating allowing you to use most
                  cleaners. Please STAY AWAY from any cleaner claiming to make
                  the floor shinier. These cleaners contain wax as an additive
                  that will stick to your floor and cause dirt &amp; soil to
                  stick to the floor, making it almost impossible to remove
                  (degreaser will remove).
                </li>
                <li className="desktopParagraph my-4">
                  You can select any brand of hard surface floor cleaner (no
                  wax-no shine) or you can make your own with apple cider
                  vinegar. The acidity in the vinegar helps remove dirt and
                  grime without leaving a buildup of soap or wax. Simply mix one
                  cup of cider vinegar with a gallon of hot water and use a
                  very, very damp mop to clean, rinsing the mop frequently with
                  hot water (hot as possible) to prevent dirt residue from being
                  spread.
                </li>
              </ul>
            </div>
            <h2 className="desktopCaption text-lg-start mt-4">
              Tips to Help Engineered Wood Flooring Last Longer
            </h2>
            <div className="text-start desktopParagraph my-4">
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Place a doormat or throw rug at every entrance to catch grit
                  and dirt that can damage (dull) floors.
                </li>
                <li className="desktopParagraph my-4">
                  VERY IMPORTANT! Prevent dents &amp; scratches from furniture
                  by installing felt pads (peel &amp; stick) on ALL furniture.
                  Heavily used furniture such as chairs and barstools will need
                  the felt pads replaced at least once or twice per year. We
                  recommend you purchase felt pads in all shapes and sizes to
                  accommodate the variety of furniture in your home.
                </li>
                <li className="desktopParagraph my-4">
                  Rolling chairs with plastic wheels will dull &amp; scratch the
                  finish overtime. To prevent this please use chairs with rubber
                  wheels or use chairs with no wheels and add felt pads to the
                  legs.
                </li>
              </ul>
            </div>
            <hr
              style={{
                borderTopWidth: "2px",
                opacity: "1",
                color: colors.Primary,
              }}
            />
          </animated.div>

          <animated.div style={GetAnimation(750)}>
            <h2 className="desktopCaption text-lg-start mt-4">
              Porcelain Tile:
            </h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                Your beautiful new Porcelain tile from Flooring Creations can
                maintain its strength and beauty for years to come. With proper
                care, your porcelain tile can easily have a decades-long
                lifespan.
              </p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Regular, basic cleaning with warm water and mild soap is
                  sufficient to keep porcelain tile looking good. You can also
                  use gentle, everyday multipurpose spray cleaner to remove soap
                  scum, hard water deposits, and mildew. Do not use cleaning
                  products that contain acids or ammonia (and other harsh
                  chemicals) as these can damage grout and glazed surfaces of
                  the tile. Choose products that are compatible with your grout
                  to avoid damage or discoloration of the grout. Always dry your
                  porcelain thoroughly after cleaning.
                </li>
                <li className="desktopParagraph my-4">
                  While porcelain is resistant to scratches, etching and stains,
                  these surfaces are not damage proof. Take care to avoid
                  exposing porcelain to permanent inks or dyes, as these might
                  not be removable. If porcelain surfaces are exposed to
                  permanent marker or dye, rinse the area with water as soon as
                  possible then apply approved cleaning product. Rinse and dry
                  thoroughly. Although porcelain is resistant to stains from
                  juice, wine, coffee and other food substances, it is best to
                  wipe up spills immediately to prevent possible stains.
                </li>
                <li className="desktopParagraph my-4">
                  You can select any brand of hard surface floor cleaner (no
                  wax-no shine) or you can make your own with apple cider
                  vinegar. The acidity in the vinegar helps remove dirt and
                  grime without leaving a buildup of soap or wax. Simply mix one
                  cup of cider vinegar with a gallon of hot water and use a
                  very, very damp mop to clean, rinsing the mop frequently with
                  hot water (hot as possible) to prevent dirt residue from being
                  spread.
                </li>
                <li className="desktopParagraph my-4">
                  To remove sealers, waxes and other floor finishes from
                  porcelain tile or countertops, use a tile sealer and adhesive
                  remover. Always test a small area first. Apply a liberal
                  amount of undiluted sealer and adhesive remover in a
                  manageable area. Allow it to sit without drying until sealer
                  residues on the surface soften. Reapply if necessary until
                  sealer softens and can be removed by wiping off with a cotton
                  towel or sponge. If necessary, agitate with a nylon scrub pad.
                  Rinse thoroughly with clean water.
                </li>
              </ul>
            </div>
            <h2 className="desktopCaption text-lg-start mt-4">Grout Care</h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                The purpose of grout is twofold, to fill the joint between the
                tiles and to provide a space for the tile to flex. It decreases
                breakage of tile and becomes a permanent, integral component of
                the finished installation. Grout is one of the more difficult
                parts of maintaining a porcelain tile floor. It tends to capture
                dirt and darkens over time. It requires more specialized
                cleaning and maintenance than tile. Choosing a large format tile
                such as 12 x 24, 24 x 24 and 24 x 48 inch tiles will help
                minimize your concern over grout upkeep.
              </p>
              <p>
                Grout needs to be cleaned on a periodic basis to remove any
                surface buildup. Routine grout cleaning can be done with a daily
                concentrated household or commercial cleaner depending on the
                application. When heavy-duty grout cleaning is required, you
                will need to use a professional strength tile and grout cleaner
                that is capable of removing grease, soap scum, oils, mildew
                stains, algae, and synthetic or acrylic waxes from the grout
                joints. Select a product that does not contain polluting
                chemicals and is low VOC. This type of product can be purchased
                from most home centers, or through your local, professional
                floor covering dealer. You might also consider professional tile
                &amp; grout cleaning companies.
              </p>
            </div>
            <hr
              style={{
                borderTopWidth: "2px",
                opacity: "1",
                color: colors.Primary,
              }}
            />
          </animated.div>

          <animated.div style={GetAnimation(950)}>
            <h2 className="desktopCaption text-lg-start mt-4">Carpet: </h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                You've made a great investment by choosing carpet from Flooring
                Creations. Your new carpet will last for years, and with the
                right care and maintenance, you'll be able to keep it looking
                new and extend its life. Cleaning your carpet is easier than you
                think. All it takes is the right vacuum cleaner, cleaning
                products and a little know-how.
              </p>
              <p>
                Here are a few tips on removing common stains the right way:
              </p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Always treat the affected area immediately. The longer the
                  spill sits there, the harder it'll be to remove the stain.
                </li>
                <li className="desktopParagraph my-4">
                  For a food spill, gently remove as much solid material as you
                  can with a spoon, or a dull knife. Add water and blot, using
                  detergent sparingly if needed. Then, using the highest suction
                  function, vacuum back and forth, adding more water to the
                  stain as you go until completely clean.
                </li>
                <li className="desktopParagraph my-4">
                  If you need a spot removal solvent, use a product approved by
                  The Carpet and Rug Institute of America. Apply several drops
                  to a clean white cloth and blot the carpet in an inconspicuous
                  area. If you notice a change in the carpet color, consult a
                  professional carpet cleaner.
                </li>
                <li className="desktopParagraph my-4">
                  If stains remain after cleaning, moisten tufts in the stained
                  area with 3% hydrogen peroxide and let stand for one hour.
                  Blot and repeat until completely clean.
                </li>
              </ul>
            </div>
            <h2 className="desktopCaption text-lg-start mt-4">
              Keeping Your Carpet Beautiful
            </h2>
            <div className="text-start desktopParagraph my-4">
              <p>
                Follow these simple tips to keep your carpet's color and texture
                looking fresh.
              </p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Vacuum your carpet regularly to prevent soil from embedding
                  itself in the pile.
                </li>
                <li className="desktopParagraph my-4">
                  Depending on the type of carpet you have, you'll want to use a
                  vacuum with a rotating brush, a beater bar, or suction only.
                </li>
                <li className="desktopParagraph my-4">
                  Keeping traffic and other use factors in mind, consider
                  professional cleaning every 12 to 18 months.
                </li>
                <li className="desktopParagraph my-4">
                  Use scissors to clip sprouts and snags. Don't pull on them—you
                  might damage the carpet.
                </li>
                <li className="desktopParagraph my-4">
                  If your carpet is burned, simply remove the tops of the dark,
                  burnt fibers with curved fingernail scissors. If the burn is
                  extensive, you may need to patch or replace it.
                </li>
                <li className="desktopParagraph my-4">
                  Remove heavy furniture dents by stroking the dented area with
                  the edge of a coin. You can also use a hair dryer or a steam
                  iron, but be very careful not to touch the carpet with the
                  iron.
                </li>
                <li className="desktopParagraph my-4">
                  For extensive water damage, consult professional cleaners to
                  dry your carpet from the front and back.
                </li>
              </ul>
            </div>
            <div className="text-start desktopParagraph my-4">
              <p>Tips for Soft Carpet</p>
              <ul className="text-start">
                <li className="desktopParagraph my-4">
                  Adjustable Height — Use the highest setting where appropriate.
                </li>
                <li className="desktopParagraph my-4">
                  Efficient Airflow — Avoid vacuums with very concentrated or
                  sealed suction.
                </li>
                <li className="desktopParagraph my-4">
                  Large Wheels — A vacuum with large wheels is easier to move
                  across soft carpeting.
                </li>
              </ul>
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default CareMaintenance;
